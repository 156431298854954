import { Card, Typography } from "@mui/material";
import { Box } from "@maysoft/common-component-react";

import useCabinClassCodenames from "hooks/useCabinClassCodenames";
import RefundAmendHistory from "./operation/containers/RefundAmendHistory";
import TransferAmendHistory from "./operation/containers/TransferAmendHistory";
import AdditionServicesHistory from "./operation/containers/AdditionServicesHistory";
import useAdditionalServiceCodenames from "components/Booking/BookingDetailContainer/Popup/hooks/useAdditionalServiceCodenames";

import { IBookingDetailAmend } from "../interfaces";
import { BookingDetailAmendType } from "constants/enum";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";



const CardOperationHistory = () => {
    const cabinClassCodenames = useCabinClassCodenames();

    const itemCodenames = useAdditionalServiceCodenames();

    const { amendBookingDetails, userMap, currency } = useAddMoreBookingServiceContext();

    const amendBookingDetailsGroupByStartTime = amendBookingDetails.reduce((p, detail) => {
        const key = detail.createTime;
        if (!p[key]) {
            p[key] = {
                type: detail.amendType,
                data: [],
            };
        }
        p[key].data.push(detail);
        return p;
    }, {} as Record<string, { type: BookingDetailAmendType, data: IBookingDetailAmend[] }>);

    const data = Object.entries(amendBookingDetailsGroupByStartTime).sort((a, b) => parseInt(a[0]) - parseInt(b[0]));

    return (
        <Card>
            <Box display="flex" flexDirection="column" component="section">
                <Box display="flex" flexDirection="column" bgColor="#EBF0FF" py={1} px={2}>
                    <Typography variant="h6">Lịch sử thao tác</Typography>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    p={2}
                    gap={2}
                    sx={{
                        "& > div": {
                            borderTop: "1px solid #E0E0E0"
                        },
                        "& > div:first-child": {
                            borderTop: "none"
                        },
                    }}>
                    {data.map((d, index) => {
                        const { "0": startTime, "1": details } = d;
                        const { type, data } = details;
                        const startTimeNumber = parseInt(startTime);
                        const amount = data.reduce((p, detail) => p + detail.amount, 0);
                        const commonProps = {
                            index: index + 1,
                            details: data,
                            userMap,
                            createTime: startTimeNumber,
                            currency,
                            amount
                        }

                        return (
                            <Box key={index} display="flex" flexDirection="column" gap={2}>
                                {type === BookingDetailAmendType.Transfer && (
                                    <TransferAmendHistory  {...commonProps} cabinClassCodenames={cabinClassCodenames} />
                                )}
                                {type === BookingDetailAmendType.AdditionService && (
                                    <AdditionServicesHistory  {...commonProps} itemCodenames={itemCodenames} />
                                )}
                                {type === BookingDetailAmendType.PartialRefund && (
                                    <RefundAmendHistory  {...commonProps} />
                                )}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Card>
    )
}

export default CardOperationHistory;