import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
// import { ModuleOrder } from "@maysoft/sale-common-react";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Constants from "../../constants";
import useModule from "hooks/useModule";
import ModuleOrder from "components/Order";

import { DashboardLayout } from "layout";

const OrderScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

        toDate: !Helpers.isNullOrEmpty(searchParams.get("toDate")) ? searchParams.get("toDate") : moment().endOf("month").unix(),
        fromDate: !Helpers.isNullOrEmpty(searchParams.get("fromDate")) ? searchParams.get("fromDate") : moment().startOf("month").unix(),

        searchText: searchParams.get("searchText"),
        partnerName: searchParams.get("partnerName"),
        orderStatus: Helpers.isNullOrEmpty(searchParams.get("orderStatus")) ? undefined : Number(searchParams.get("orderStatus")),
        paymentStatus: Helpers.isNullOrEmpty(searchParams.get("paymentStatus")) ? undefined : Number(searchParams.get("paymentStatus")),
        orderDetailType: Helpers.isNullOrEmpty(searchParams.get("orderDetailType")) ? undefined : Number(searchParams.get("orderDetailType")),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.ORDER_LIST,
            detail: Screens.ORDER_EDIT,
        },
        keyOfStrings: "ORDER",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <ModuleOrder
                {...moduleProps}
            />
        </DashboardLayout>
    );
};

export default OrderScreen;
