import { Card } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

const CardCustomerOrder = ({ organization }: {
    organization: any
}) => {
    return (
        <Card>
            <Box padding={2}>
                <Typography variant="h6">
                    {"Thông tin khách hàng"}
                </Typography>
                <Box
                    paddingTop={1}
                    paddingLeft={2}
                    paddingRight={2}
                    sx={{
                        gap: 1,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="button">
                        {"Tên công ty"}:&ensp;
                        {organization?.name?.value?.["vi"]}
                    </Typography>
                    <Typography variant="button">
                        {"Mã công ty"}:&ensp;
                        {organization?.code}
                    </Typography>
                    <Typography variant="button">
                        {"Email"}:&ensp;
                        {organization?.organizationProfiles?.[0]?.email}
                    </Typography>
                    <Typography variant="button">
                        {"Số điện thoại"}:&ensp;
                        {organization?.organizationProfiles?.[0]?.phoneNumber}
                    </Typography>
                </Box>
            </Box>
        </Card>
    )
};

export default CardCustomerOrder;