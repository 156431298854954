import { useCommonComponentContext } from "@maysoft/common-component-react";
import { IItem } from "@maysoft/sale-common-react";
import Helpers from "commons/helpers";
import { ICodename } from "commons/interfaces";
import Strings from "constants/strings";
import { useState, useMemo, useEffect } from "react";
import ItemService from "services/sale/item.service";

const itemService = new ItemService();
const useAdditionalServiceCodenames = () => {
    const language = Strings.getLanguage();
    const { serviceCode, userInfo } = useCommonComponentContext();
    const organizationId = userInfo.userProfile?.organizationId;

    const [items, setItems] = useState<IItem[]>([]);
    const itemCodenames = useMemo(() => {
        if (!items) return [];
        const out: ICodename[] = [];
        const last: ICodename[] = [];
        items.forEach(item => {
            const nameByLanguage = Helpers.getDefaultValueMultiLanguage(item.name, language);
            if (nameByLanguage === "Khác") {
                last.push({ name: nameByLanguage, code: item.id, detail: item })
            } else {
                out.push({ name: nameByLanguage, code: item.id, detail: item})
            }
        })
        return out.concat(last);
    }, [items, language])
    useEffect(() => {
        if (!organizationId || !serviceCode) return;
        (async () => {
            try {
                const ADDITIONAL_SERVICE_TEMPLATE_CODE = "DVBS01";
                const getPagedRequestData = {
                   serviceCode,
                   organizationId,
                   templateCode: ADDITIONAL_SERVICE_TEMPLATE_CODE, 
                   pageSize: 15,
                }
                const query = Helpers.handleFormatParams(getPagedRequestData);
                const result = await itemService.getPaged(query);
                setItems(result.items);
            } catch (e: any) {
            }
        })()
    }, [organizationId, serviceCode])
    return itemCodenames;
}

export default useAdditionalServiceCodenames;