import { useMemo } from "react";
import { Card } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import { PaymentStatus } from "constants/enum";
import { ICodename } from "commons/interfaces";

const CardPaymentOrder = ({ data, paymentTypeList }: {
    data: any,
    paymentTypeList: ICodename[]
}) => {

    const isCheck = useMemo(() => (
        [PaymentStatus.Refunded, PaymentStatus.WaitingRefund].includes(data?.paymentStatus)
    ), [data?.paymentStatus]);

    return (
        <Card>
            <Box padding={2}>
                <Typography variant="h6">
                    {"Thông tin thanh toán"}
                </Typography>
                <Box
                    paddingTop={1}
                    paddingLeft={2}
                    paddingRight={2}
                >
                    <BoxTitleValue
                        title={
                            <Typography variant="button">
                                {`Tổng tiền`}
                            </Typography>
                        }
                        value={
                            <Typography variant="button" color={isCheck ? "error" : "dark"}>
                                {isCheck ? "- " : ""}
                                {`${Helpers.formatCurrency((data?.amount || 0) - (data?.serviceFee || 0))} `}
                                {data?.currency || Constants.CURRENCY_DEFAULT}
                            </Typography>
                        }
                    />

                    <BoxTitleValue
                        title={
                            <Typography variant="button">
                                {"Phí dịch vụ"}
                            </Typography>
                        }
                        value={
                            <Typography variant="button">
                                {`${Helpers.formatCurrency(data?.serviceFee || 0)} `}
                                {data?.currency || Constants.CURRENCY_DEFAULT}
                            </Typography>
                        }
                    />

                    <BoxTitleValue
                        title={
                            <Typography variant="button">
                                {`Tổng cộng`}
                            </Typography>
                        }
                        value={
                            <Typography variant="button" color={isCheck ? "error" : "dark"}>
                                {isCheck ? "- " : ""}
                                {`${Helpers.formatCurrency(data?.amount || 0)} `}
                                {data?.currency || Constants.CURRENCY_DEFAULT}
                            </Typography>
                        }
                    />

                    <BoxTitleValue
                        title={
                            <Typography variant="button" fontWeight="bold">
                                {`Phương thức thanh toán`}
                            </Typography>
                        }
                        value={
                            <Typography variant="button" fontWeight="bold">
                                {paymentTypeList.find(el => el.code === data?.paymentMethod)?.name || ""}
                            </Typography>
                        }
                    />
                </Box>
            </Box>
        </Card>
    )
};

export default CardPaymentOrder;

const BoxTitleValue = (props: {
    title: JSX.Element,
    value: JSX.Element,
}) => (
    <Box sx={{
        gap: 1,
        paddingBottom: 1,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "end",
        justifyContent: "space-between",
    }}>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.title}
        </Box>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.value}
        </Box>
    </Box>
)