import axios from "axios";
import moment from "moment";
import { setLocale } from "yup";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Constants from "./constants";
import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import OrderScreen from "screens/order";
import NotFoundScreen from "screens/notFound";
import DashboardScreen from "screens/dashboard";
import ProtectedRoute from "routes/protectedRoute";
import LoginRedirectScreen from "screens/login/loginRedirect";

import { RootState } from "store";
import { MainLayout } from "layout";
import { tabArrLanguage } from "assets/data";
import { useRenderRoute } from "routes/routes";
import { useMapScreenName } from "routes/mapScreenName";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setTargerScreen } from "store/slice/titleRoute.slice";
import { IMenuItem, IRecordMenuDetail } from "commons/interfaces";

import { useAuth } from "providers/authProvider";
import { SaleCommonProvider } from "@maysoft/sale-common-react";
import { CommonComponentProvider } from "@maysoft/common-component-react";
// import { MessageCommonProvider } from "@maysoft/circle-common-react";

import "./classes/globals.css";
import "./classes/utilities.css";
import "@maysoft/sale-common-react/dist/index.css";
import "@maysoft/common-component-react/dist/index.css";


function App() {
    const auth = useAuth();
    const dispatchRedux = useDispatch();
    const dataMapScreenName = useMapScreenName();
    const currentLanguage = Strings.getLanguage();

    const [renderKey, setRenderKey] = useState(0);

    const routes = useRenderRoute(renderKey);

    const [menus, setMenus] = useState<IMenuItem[]>(routes);
    const [menuDetails, setMenuDetails] = useState<IRecordMenuDetail[]>([]);

    const userInfo = useSelector((state: RootState) => state.userInfo);
    const targetScreen: string = useSelector((state: RootState) => state.titleRoute.targerScreen);

    useEffect(() => {
        moment.locale(currentLanguage);
        setLocale({
            // use constant translation keys for messages without values
            mixed: {
                required: Strings.Validation.REQUIRED,
            },
            string: {
                email: Strings.Validation.EMAIL_ADDRESS,
            },
        });
    }, [currentLanguage]);

    useEffect(() => {
        const pathName = window.location.pathname;
        if (pathName !== Screens.LOGIN_REDIRECT && targetScreen !== `${pathName}${window.location.search}`) {
            dispatchRedux(setTargerScreen(`${pathName}${window.location.search}`));
        }

        const setKey = () => {
            setRenderKey(Date.now());
        };

        __EventEmitter.addListener(Constants.EventName.LANGUAGE_CHANGE, setKey);

        return () => {
            __EventEmitter.removeListener(Constants.EventName.LANGUAGE_CHANGE, setKey);
        };
    }, []);

    useEffect(() => {
        if (auth?.user == null) {
            return;
        } else {
            setMenuDetails([...userInfo?.menuDetails || []]);
        }

    }, [auth.user, userInfo?.menuDetails]);

    const renderRoute = useMemo(() => {
        const arrRoute: any[] = [];

        menuDetails.forEach((item, key) => {
            arrRoute.push(
                <Route
                    key={key}
                    path={item.externalUrl}
                    element={dataMapScreenName[item.screenName] ?? <></>}
                />
            );

            if (!Helpers.isNullOrEmpty(item.extraInformation)) {
                const extraInformation = JSON.parse(item.extraInformation);
                if (Array.isArray(extraInformation)) {
                    extraInformation.forEach((el) => {
                        arrRoute.push(
                            <Route
                                key={key}
                                path={el.ScreenPath || el.screenPath}
                                element={dataMapScreenName[el.ScreenName || el.screenName] ?? <></>}
                            />
                        );
                    });
                } else {
                    arrRoute.push(
                        <Route
                            key={key}
                            path={extraInformation?.ScreenPath || extraInformation?.screenPath}
                            element={dataMapScreenName[extraInformation?.ScreenName || extraInformation?.screenName] ?? <></>}
                        />
                    );
                }
            }
        });

        return arrRoute;
    }, [dataMapScreenName, menuDetails]);

    const renderMainLayout = useMemo(() => (<MainLayout routes={menus} menuDetail={menuDetails} />), [menus, menuDetails]);

    return (
        <CommonComponentProvider
            value={{
                axios: axios as any,
                userInfo: userInfo,
                language: currentLanguage,
                listMultiLanguage: tabArrLanguage,
                clientId: Constants.CLIENT_ID,
                tenantCode: Constants.TENANT_CODE,
                serviceCode: Constants.SERVICE_CODE,
                organizationId: userInfo?.currentOrganization,
                dataMapScreenName: dataMapScreenName,
                onShowLoading() {
                    dispatchRedux(showLoading(true));
                },
                onHideLoading() {
                    dispatchRedux(showLoading(false));
                },
                onSuccess(msg) {
                    dispatchRedux(setDataAlert({ message: msg, type: "success" }));
                },
                onError(msg, type) {
                    dispatchRedux(showLoading(false));
                    dispatchRedux(setDataAlert({ message: msg, type: type || "error" }));
                },
            }}
        >
            <SaleCommonProvider>
                {/* <MessageCommonProvider> */}
                <BrowserRouter key={renderKey}>
                    <Routes>
                        <Route path={Screens.LOGIN_REDIRECT} element={<LoginRedirectScreen />} />
                        <Route path={Screens.HOME} element={<ProtectedRoute>{renderMainLayout}</ProtectedRoute>}>
                            <Route index element={<DashboardScreen />} />
                            {renderRoute}
                            <Route path="*" element={<NotFoundScreen />} />
                        </Route>
                        <Route path={Screens.ORDER_EDIT} element={<OrderScreen />} />
                    </Routes>
                </BrowserRouter>
                {/* </MessageCommonProvider> */}
            </SaleCommonProvider>
        </CommonComponentProvider>
    );
}

export default App;