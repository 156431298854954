import DashboardScreen from "screens/dashboard";
import ProfileScreen from "screens/profile";

import RoleScreen from "screens/role";
import GroupScreen from "screens/group";
import StaffScreen from "screens/staff";
import OrganizationScreen from "screens/organization";

import ItemScreen from "screens/item";
import OrderScreen from "screens/order";
import CustomerScreen from "screens/customer";
import CodenameScreen from "screens/codename";
import AttributeScreen from "screens/attribute";
import ManufacturerScreen from "screens/manufacturer";
import AmendBookingScreen from "screens/booking/amend";
import HotelBookingScreen from "screens/booking/hotel";
import FlightBookingScreen from "screens/booking/flight";
import PaymentInvoiceScreen from "screens/paymentInvoice";
import ApproveDepositScreen from "screens/approveDeposit";
import RequestDepositScreen from "screens/requestDeposit";
import PaymentAccountScreen from "screens/paymentAccount";
import ServiceFeeScreen from "screens/systemSettingCommon/serviceFee";
import PaymentInvoiceDetailScreen from "screens/paymentInvoice/detail";
import SettingLoyalCustomerScreen from "screens/systemSettingCommon/loyaltyCustomer";



export const useMapScreenName = () => {
    let data: { [key: string]: JSX.Element } = {
        ProfileScreen: <ProfileScreen />,
        DashboardScreen: <DashboardScreen />,

        RoleScreen: <RoleScreen />,

        CustomerScreen: <CustomerScreen />,
        SettingLoyalCustomerScreen: <SettingLoyalCustomerScreen />,

        StaffScreen: <StaffScreen />,
        GroupScreen: <GroupScreen />,
        OrganizationScreen: <OrganizationScreen />,

        ManufacturerScreen: <ManufacturerScreen />,
        ItemScreen: <ItemScreen />,

        AmendBookingScreen: <AmendBookingScreen />,
        HotelBookingScreen: <HotelBookingScreen />,
        FlightBookingScreen: <FlightBookingScreen />,

        OrderScreen: <OrderScreen />,

        PaymentInvoiceScreen: <PaymentInvoiceScreen />,
        PaymentInvoiceDetailScreen: <PaymentInvoiceDetailScreen />,

        CodenameScreen: <CodenameScreen />,
        AttributeScreen: <AttributeScreen />,
        ServiceFeeScreen: <ServiceFeeScreen />,
        PaymentAccountScreen: <PaymentAccountScreen />,
        RequestDepositScreen: <RequestDepositScreen />,
        ApproveDepositScreen: <ApproveDepositScreen />,

    };

    return data;
};
