import { useEffect, useState } from "react";
import { KeyModuleScreen, IPropModuleContainer } from "@maysoft/common-component-react";

import OrderListContainer from "./listOrder";
import OrderDetailContainer from "./DetailOrder";
import { IReqGetPagedOrder } from "services/sale/order.service";



const ModuleOrder = (props: IPropModuleContainer<IReqGetPagedOrder> & {
    hidenActionExport?: boolean;
}) => {
    const currentPath = window.location.pathname || "";

    const [idDetail, setIdDetail] = useState<string | undefined>(undefined);

    useEffect(() => { setIdDetail(props.idDetail); }, [props.idDetail]);

    const NewComponentTemp = (
        <OrderDetailContainer
            mode={props.mode}
            idDetail={idDetail}

            onChangeMode={props.onChangeMode}
            onGoBack={() => {
                setIdDetail(undefined);
                props.onGoBack();
            }}
        />
    );

    const listContainers: { [key in KeyModuleScreen]?: JSX.Element; } = {
        "list": (
            <OrderListContainer
                hidenActionExport={props.hidenActionExport}

                onGetPaged={props.onGetPaged}
                requestGetPaged={props.requestGetPaged}
                onNavigate={(data) => {
                    setIdDetail(data.id);
                    props.onNavigate(data);
                }}
            />
        ),
        "create": NewComponentTemp,
        "detail": NewComponentTemp,
        "update": NewComponentTemp,
    };

    const renderContainerView = () => {
        const itemFind = Object.keys(props.keyModuleContainer).find((key) => ((props.keyModuleContainer as any)[key]?.pathName === currentPath));
        return (listContainers as any)[itemFind];
    };

    return <>{renderContainerView()}</>;
};

export default ModuleOrder;
