import { IBookingDetail, IBookingDetailFlightExtraInfo, IBookingDetailFlightExtraInfoIssueTicketInfos, IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import Helpers from "./helpers";
import Strings from "constants/strings";
import { ConfirmStatus } from "constants/enum";
import { IBookingDetailAmend, IBookingDetailFlightAmendServicePopupExtraInfo } from "components/Booking/BookingDetailContainer/interfaces";
import moment from "moment";
import Constants from "constants/index";

const getBookingDetailsWithExtraInfoParsed = (details: IBookingDetail[]) => {
    let isAllExtraInfoParsed = true;
    const newDetails = details.map((detail) => {
        const newDetail = { ...detail };
        if (!detail.extraInfo) {
            return detail;
        }
        if (detail.extraInfoParsed) {
            return detail;
        }

        newDetail.extraInfoParsed = Helpers.converStringToJson(detail.extraInfo) as IBookingDetailFlightExtraInfo;
        isAllExtraInfoParsed = false;
        if (newDetail.extraInfoParsed) {
            if (!newDetail.extraInfoParsed.IssueTicketInfoParsed && newDetail.extraInfoParsed.IssueTicketInfo) {
                newDetail.extraInfoParsed.IssueTicketInfoParsed = Helpers.converStringToJson(newDetail.extraInfoParsed.IssueTicketInfo) as IBookingDetailFlightExtraInfoIssueTicketInfos;
            } 
            newDetail.extraInfoParsed.FreeBaggage.forEach(baggage => {
                if (baggage.is_hand_baggage !== undefined) {
                    baggage.IsHandBaggage = baggage.is_hand_baggage;
                }
            });
        }
        return newDetail;
    })

    return isAllExtraInfoParsed ? details : newDetails;
}
const getBookingDetailsWithAmendExtraInfoParsed = (details: IBookingDetailAmend[]) => {
    return details.map((detail) => {
        const newDetail = { ...detail };
        if (!detail.extraInfo) {
            return detail;
        }
        if (detail.amendExtraInfoParsed) {
            return detail;
        }
        newDetail.amendExtraInfoParsed = Helpers.converStringToJson(detail.extraInfo) as IBookingDetailFlightAmendServicePopupExtraInfo;
        return newDetail;
    })
}

const extractFlightMetadataFromParsedExtraInfo = (
    extraInfo: IBookingDetailFlightExtraInfo
) => {
    if (!extraInfo) {
        throw Error("No extra info provided");
    }

    const { IssueTicketInfoParsed, SegmentsList = [], CabinClass, Leg, ArrivalDT, DepartDT, CarrierMarketingObj, DepartDate, ArrivalDate, IsInternational } = extraInfo || { };
    const ticketNumber =
        IssueTicketInfoParsed?.Booking?.Itineraries?.[0]?.e_tickets?.[0]
            ?.ticket_number;
    const itineraryData = IssueTicketInfoParsed?.Booking?.Itineraries.find(i => i.leg === Leg);
    const reservationCode = itineraryData?.reservation_code;
    const baggages = itineraryData?.free_baggages || [];

    const length = SegmentsList.length;
    const departPlace = SegmentsList[0]?.DepartPlace;
    const arrivalPlace = SegmentsList[Math.min(0, length - 1)]?.ArrivalPlace;

    return {
        success: true,
        arrivalDT: ArrivalDT,
        arrivalPlace,
        departDT: DepartDT,
        departPlace,
        ticketNumber,
        cabinClass: CabinClass,
        reservationCode,
        baggages,
        carrierMarketing: {
            name: CarrierMarketingObj.Name[Strings.getLanguage() as "en" | "vi"],
            logoUrl: CarrierMarketingObj.LogoUrl,
        },
        departDate: DepartDate,
        arrivalDate: ArrivalDate,
        isInternational: IsInternational,
    };
};
const extractFlightMetadataFromAmendParsedExtraInfo = (
    extraInfo: IBookingDetailFlightAmendServicePopupExtraInfo,
    userId: string,
) => {
    if (!extraInfo) {
        throw Error("No extra info provided");
    }

    const { users } = extraInfo || { };
    const flight = users?.find(u => u.id === userId)?.flight;

    return {
        success: !!flight,
        arrivalDT: moment.utc(flight?.arrivalTime * 1000).format(Constants.DateFormat.DDMMYYYY_HHMM),
        departDT: moment.utc(flight?.departTime * 1000).format(Constants.DateFormat.DDMMYYYY_HHMM),

        arrivalPlace: flight?.arrivalPlaceObj?.code.toString(),
        departPlace: flight?.departPlaceObj?.code.toString(),

        ticketNumber: flight?.ticketNumber,
        cabinClass: flight?.cabinClass,
        reservationCode: flight?.reservationCode,
        baggages: flight?.baggage,

        carrierMarketing: {
            name: flight?.airlineObj?.name,
            logoUrl: "",
        },
        departDate: flight?.departTime,
        arrivalDate: flight?.arrivalTime,
        isInternational: flight?.isInternational,
    }
};
const getFlightExtraInfo = (flight: any, userId?: string) => {
    if ("amendExtraInfoParsed" in flight) {
        return extractFlightMetadataFromAmendParsedExtraInfo(flight.amendExtraInfoParsed, userId);
    }
    return extractFlightMetadataFromParsedExtraInfo(flight.extraInfoParsed ?? getBookingDetailsWithExtraInfoParsed([flight])[0].extraInfoParsed);
}

const getBookingReservationCode = (details: IBookingDetail[]) => {
    for (let i = 0; i < details.length; i++) {
        const { reservationCode } = getFlightExtraInfo(details[i]);
        return reservationCode;
    }
    return undefined;
}
const isBookingWaitingForReservationCode = (booking: IDetailBooking) => {
    const detailsWithExtraInfo = getBookingDetailsWithExtraInfoParsed(booking.bookingDetails);
    return booking.confirmStatus === ConfirmStatus.Completed && getBookingReservationCode(detailsWithExtraInfo) === undefined;
}
const isBookingIssued = (booking: IDetailBooking) => {
    const detailsWithExtraInfo = getBookingDetailsWithExtraInfoParsed(booking.bookingDetails);
    return booking.confirmStatus === ConfirmStatus.Completed && getBookingReservationCode(detailsWithExtraInfo) !== undefined;
}

export const BookingHelpers = {
    getBookingDetailsWithExtraInfoParsed,
    getBookingDetailsWithAmendExtraInfoParsed,
    getFlightExtraInfo,

    getBookingReservationCode,

    isBookingIssued,
    isBookingWaitingForReservationCode,
};