import {
    Box,
    Modal,
} from "@maysoft/common-component-react";
import { useCallback, useMemo, useState } from "react";
import { ITransferBookingDetailSubOrderViewModel, ITransferBookingDetailViewModel } from "../interfaces";
import useBookingDetailSubmit, { IUseBookingDetailPopupSubmitProps } from "./hooks/useBookingDetailSubmit";
import { CabinClass } from "constants/enum";
import useLoadBookingDetailServiceFee from "./hooks/useLoadServiceFee";
import useCabinClassCodenames from "hooks/useCabinClassCodenames";
import { array, number, object, string } from "yup";
import Helpers from "commons/helpers";
import useAirlineCodenames from "hooks/useAirlineCodenames";
import ConfirmModeFlightBookingDetailTransferFlightPopup from "./containers/TransferFlight/ConfirmModeFlightBookingDetailTransferFlightPopup";
import EditModeFlightBookingDetailTransferFlightPopup from "./containers/TransferFlight/EditModeFlightBookingDetailTransferFlightPopup";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import Strings from "constants/strings";


interface TransferFlightPopupProps extends Pick<IUseBookingDetailPopupSubmitProps, "onCompleted"> {
    visible: boolean;
    onClose: () => void;
    onCompleted?: () => Promise<void>;
}

const subOrderSchema = object().shape({
    differentAmount: number().min(0).required(),
    transferFee: number().min(0).required(),
    noshowFee: number().min(0).nullable(),
})
const transferRequestSchema = array().of(object().shape({
    _id: string().required(),
    departTime: number().required(),

    arrivalTime: number().required(),
    cabinClass: string().oneOf(Object.values(CabinClass)).required(),
    baggage: string().required(),
    reservationCode: string().required(),
    ticketNumber: string().nullable(),
    seatNumber: string().nullable(),

    arrivalPlace: string().required(),
    departPlace: string().required(),
    airline: string().required(),
}));

const BookingDetailTransferFlightPopup = (props: TransferFlightPopupProps) => {
    const [mode, setMode] = useState<"edit" | "confirm">("edit");
    const { currency, data, users } = useAddMoreBookingServiceContext();

    const { loading: isSubmitLoading, onTransferSubmit, renderLoadingContent, renderPaymentContent } = useBookingDetailSubmit({ onCompleted: props.onCompleted, data });
    const { totalFee, loading: isLoadingServiceFee, serviceFeeByBookingDetailId, onCalculateServiceFeeForTransfer } = useLoadBookingDetailServiceFee(data);
    const [transferRequests, setTransferRequests] = useState<ITransferBookingDetailViewModel[]>([]);
    const checkedTransferRequest = useMemo(() => {
        return transferRequests.filter(r => r.checked);
    }, [transferRequests])
    const [transferRequestErrors, setTransferRequestErrors] = useState<Record<string, { [key: string]: string | undefined }> | null>(null);
    const [subOrderData, setSubOrderData] = useState<ITransferBookingDetailSubOrderViewModel>({
        differentAmount: 0,
        noshowFee: 0,
        transferFee: 0,
    });
    const [subOrderErrors, setSubOrderErrors] = useState<{ [key in keyof ITransferBookingDetailSubOrderViewModel]: string | undefined } | null>(null);
    const amount = subOrderData.differentAmount + subOrderData.noshowFee + subOrderData.transferFee + totalFee;

    // codenames
    const cabinClassCodenames = useCabinClassCodenames();
    const { data: airlines } = useAirlineCodenames();

    const validateTransferRequests = async (requests: ITransferBookingDetailViewModel[] = checkedTransferRequest) => {
        const matchSchemaRequests = requests.map(r => ({
            ...r,
            arrivalPlace: r.arrivalPlaceObj?.code,
            departPlace: r.departPlaceObj?.code,
            airline: r.airlineObj?.code,
        }))
        try {
            await transferRequestSchema.validate(matchSchemaRequests, { abortEarly: false });
            return null;
        } catch (e: any) {
            if (e.name === "ValidationError") {
                const { value: values, inner: inners } = e;
                const errors: typeof transferRequestErrors = { };
                inners.forEach(
                    (curr: any) => {
                        const path = curr.path;
                        const firstMatchIndex = path.match(/\d+/)?.[0];
                        const relevantValue = values[firstMatchIndex] as ITransferBookingDetailViewModel;

                        if (relevantValue) {
                            errors[relevantValue._id] = errors[relevantValue._id] ?? {};
                            const newPath = path.replace(
                                `[${firstMatchIndex}]`,
                                relevantValue._id
                            );
                            errors[relevantValue._id][newPath] = curr.errors[0];
                        }
                    });

                return errors;
            }
        }
    }
    const validateSubOrderData = async () => {
        try {
            await subOrderSchema.validate(subOrderData, { abortEarly: false });
            return null;
        } catch (error) {
            const errors = Helpers.handleValidationError(error);
            return errors as typeof subOrderErrors;
        }
    }
    const handleChangeToConfirmMode = async () => {
        try {
            const transferRequestErrors = await validateTransferRequests();
            const newSubOrderErrors = await validateSubOrderData();
            if (newSubOrderErrors || transferRequestErrors) {
                setSubOrderErrors(newSubOrderErrors);
                setTransferRequestErrors(transferRequestErrors);
                return;
            }

            setMode("confirm");
        } catch (e) {
            Helpers.handleException(e);
        }
    }
    const handleChangeValue = useCallback((newTransferRequest: ITransferBookingDetailViewModel, key: keyof ITransferBookingDetailViewModel) => {
        setTransferRequests(old => {
            const index = old.findIndex(x => x._id === newTransferRequest._id);
            if (index === -1) {
                return [...old, newTransferRequest];
            }
            return old.map((x, i) => i === index ? newTransferRequest : x);
        });
        if (key === "checked") {
            onCalculateServiceFeeForTransfer(newTransferRequest);
            setTransferRequestErrors(old => {
                delete old?.[newTransferRequest._id];
                return { ...old };
            })
        }
    }, [onCalculateServiceFeeForTransfer])
    const renderContent = () => {
        const submitLoadingShown = isSubmitLoading;

        const editModeShown = !submitLoadingShown && mode === "edit";

        const confirmModeShown = !submitLoadingShown && mode === "confirm";
        return <Box>
            <Box sx={{
                display: { xs: submitLoadingShown ? "block" : "none" },
            }}>
                {renderLoadingContent()}
            </Box>

            <Box sx={{
                display: { xs: editModeShown ? "block" : "none" },
            }}>
                <EditModeFlightBookingDetailTransferFlightPopup
                    data={data}
                    handleChangeValue={handleChangeValue}

                    users={users}
                    airlines={airlines}    
                    cabinClassCodenames={cabinClassCodenames}

                    isLoadingServiceFee={isLoadingServiceFee}
                    renderPaymentContent={renderPaymentContent}

                    subOrderData={subOrderData}
                    setSubOrderData={setSubOrderData}
                    subOrderErrors={subOrderErrors}

                    amount={amount}
                    currency={currency}
                    totalFee={totalFee}
                    transferRequests={transferRequests}
                    transferRequestErrors={transferRequestErrors}
                />
            </Box>

            <Box sx={{
                display: { xs: confirmModeShown ? "block" : "none" },
            }}>
                <ConfirmModeFlightBookingDetailTransferFlightPopup
                    users={users}
                    cabinClassCodenames={cabinClassCodenames}

                    subOrderData={subOrderData}
                    checkedTransferRequest={checkedTransferRequest} 

                    amount={amount}
                    currency={currency}
                    totalFee={totalFee}
                />
            </Box>
        </Box>
    };

    const getActions = () => {
        if (isSubmitLoading) {
            return {
                hasActionButton: false,
                title: Strings.Common.PROCESSING,
            };
        }
        if (mode === "edit") {
            return {
                onAction: handleChangeToConfirmMode,
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                title:  Strings.BOOKING.ADD_SERVICE_TRANSFER_FLIGHT,
                onClickCloseIcon: props.onClose,
            };
        }

        if (mode === "confirm") {
            return {
                onAction: () =>  onTransferSubmit({ amount, serviceFeeByBookingDetailId, transferItems: checkedTransferRequest, subOrderData }),
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                closeButton: Strings.BOOKING.POPUP_GO_BACK_BUTTON,
                title: Strings.BOOKING.TRANSFER_POPUP_CONFIRM_TITLE,
                onClickCloseIcon: props.onClose,
                onClose: () => setMode("edit"),
            };
        }

        return {};
    };

    return (
        <Modal
            {...getActions()}
            fullWidth
            maxWidth="md"
            visible={props.visible}
        >
            {renderContent()}
        </Modal>
    );
};

export default BookingDetailTransferFlightPopup;