import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import { Autocomplete, Box, Button, Chip, CustomPagination, DataTable, DatePicker, FormField, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import Screens from "constants/screens";
import useDataPaymentInvoice, { IRecordCashBookV2 } from "./useDataPaymentInvoice.hook";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import CashBookService, { ICashBookExportRequest, ICashBookPagingRequest } from "services/sale/cashBook.service";



const PaymentInvoiceScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const {
        paymentTypeList,
        cashBookTypeList,
        getAmountCell,
        getSlipTypeCell,
        getSlipStatusCell,
        getOrganizationBySelectId,

    } = useDataPaymentInvoice();

    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemPathName = listPathName.find(el => el.pathName === Screens.PAYMENT_LIST);

    const [loadingDataTable, setLoadingDataTable] = useState<boolean>(false);
    const [dataRows, setDataRows] = useState<IRecordCashBookV2[]>([]);
    const [dataMapOrg, setDataMapOrg] = useState(new Map());
    const [model, setModel] = useState<{
        request?: ICashBookPagingRequest,
        requestTemp?: ICashBookPagingRequest,
    }>({});


    useEffect(() => {
        const endTime = !Helpers.isNullOrEmpty(searchParams.get("endTime")) ? searchParams.get("endTime") : moment().endOf("month").unix();
        const startTime = !Helpers.isNullOrEmpty(searchParams.get("startTime")) ? searchParams.get("startTime") : moment().startOf("month").unix();

        let newReq: any = {
            endTime: endTime,
            startTime: startTime,
            buyerIds: searchParams.get("buyerIds"),

            type: searchParams.get("type"),
            paymentType: searchParams.get("paymentType"),
            pageSize: Number(searchParams.get("pageSize") || 20),
            pageNumber: Number(searchParams.get("pageNumber") || 1),
            totalCount: itemPathName?.totalCount,
        };

        getPaged(newReq);
    }, [userProfile?.organizationId]);

    const getPaged = async (req?: ICashBookPagingRequest) => {
        try {
            // dispatch(showLoading(true));
            setLoadingDataTable(true);

            const pageSize = req?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(req?.pageNumber || 1, pageSize, req?.totalCount || 0) || 1;

            const requestData: ICashBookPagingRequest = {
                pageSize,
                pageNumber,
                type: req?.type,
                endTime: req?.endTime,
                startTime: req?.startTime,
                searchText: req?.searchText,
                paymentType: req?.paymentType,
            };

            const result = await new CashBookService().getPagedV2(requestData);

            const orgIds = [...result?.items || []].map(el => el.organizationId);
            const newDataMap = await getOrganizationBySelectId(orgIds);
            setDataMapOrg(newDataMap);

            let query: string = `?pageNumber=${requestData?.pageNumber}&pageSize=${requestData.pageSize}`;

            if (!Helpers.isNullOrEmpty(requestData?.startTime)) {
                query += `&startTime=${requestData?.startTime}`;
            }
            if (!Helpers.isNullOrEmpty(requestData?.endTime)) {
                query += `&endTime=${requestData?.endTime}`;
            }
            if (!Helpers.isNullOrEmpty(requestData?.paymentType)) {
                query += `&paymentType=${requestData?.paymentType}`;
            }
            if (!Helpers.isNullOrEmpty(requestData?.type)) {
                query += `&type=${requestData?.type}`;
            }

            setDataRows([...result?.items || []]);
            setModel({
                request: { ...requestData, totalCount: result.totalCount },
                requestTemp: { ...requestData, totalCount: result.totalCount },
            });

            navigate(Screens.PAYMENT_LIST + query, { replace: true });

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            // dispatch(showLoading(false));
            setLoadingDataTable(false);
        }
    };

    const exportCSV = async () => {
        Helpers.showConfirmAlert("Bạn chắc chắn muốn tải file về", async () => {
            try {
                dispatch(showLoading(true));

                const requestData: ICashBookExportRequest = {
                    type: model.request?.type,
                    endTime: model.request?.endTime,
                    startTime: model.request?.startTime,
                    searchText: model.request?.searchText,
                    paymentType: model.request?.paymentType,
                };

                const result = await await new CashBookService().exportV2(requestData);

                const date = moment().format("HH_mm_DD_MM_YYYY");

                var fileName = `cashbook_${date}.csv`;

                const downloadUrl = URL.createObjectURL(result);

                const a = document.createElement("a");

                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

            } catch (error) {
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            } finally {
                dispatch(showLoading(false));
            }
        });
    }

    const RenderFilterForm = () => {
        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DatePicker
                            label="Thời gian"
                            views={["month", "year"]}
                            placeholder="Nhập thời gian"
                            value={Helpers.getDateValue(model.requestTemp?.startTime)}
                            onChangeValue={(value) => {
                                const valStartTime = !Helpers.isNullOrEmpty(value) ? moment(value).startOf("month").unix() : undefined;

                                const valEndTime = !Helpers.isNullOrEmpty(value) ? moment(value).endOf("month").unix() : undefined;

                                setModel(prev => ({
                                    ...prev,
                                    requestTemp: {
                                        ...prev.requestTemp,
                                        endTime: valEndTime,
                                        startTime: valStartTime,
                                    }
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormField
                            label={"Mã đơn hàng"}
                            placeholder={"Nhập mã đơn hàng"}
                            defaultValue={model.requestTemp?.searchText}
                            onBlur={(value: any) => {
                                setModel(prev => ({
                                    ...prev,
                                    requestTemp: {
                                        ...prev.requestTemp,
                                        searchText: value,
                                    }
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            label={"Phương thức"}
                            placeholder={"Chọn phương thức"}
                            data={paymentTypeList || []}
                            defaultValue={model.requestTemp?.paymentType}
                            onChange={(value) => {
                                const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;
                                setModel(prev => ({
                                    ...prev,
                                    requestTemp: {
                                        ...prev.requestTemp,
                                        paymentType: newValue,
                                    }
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            label={"Loại giao dịch"}
                            placeholder={"Chọn loại giao dịch"}
                            data={cashBookTypeList || []}
                            defaultValue={model.requestTemp?.type}
                            onChange={(value) => {
                                const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;
                                setModel(prev => ({
                                    ...prev,
                                    requestTemp: {
                                        ...prev.requestTemp,
                                        type: newValue,
                                    }
                                }));
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const RenderChipFilter = () => (
        <>
            {
                (
                    !Helpers.isNullOrEmpty(model.request?.type)
                    || !Helpers.isNullOrEmpty(model.request?.endTime)
                    || !Helpers.isNullOrEmpty(model.request?.startTime)
                    || !Helpers.isNullOrEmpty(model.request?.searchText)
                    || !Helpers.isNullOrEmpty(model.request?.paymentType)
                )
                && (
                    <Grid item xs={12}>
                        {!Helpers.isNullOrEmpty(model.request?.startTime) &&
                            !Helpers.isNullOrEmpty(model.request?.endTime) &&
                            <Chip
                                label={"Thời gian"}
                                value={[
                                    moment(Helpers.getDateValue(model?.request?.startTime)).format("DD/MM/YYYY"),
                                    moment(Helpers.getDateValue(model?.request?.endTime)).format("DD/MM/YYYY")
                                ].join(" - ")}
                                onDelete={() => {
                                    getPaged({
                                        ...model.request,
                                        startTime: moment().startOf("month").unix(),
                                        endTime: moment().endOf("month").unix(),
                                    });
                                }}
                            />
                        }
                        {
                            !Helpers.isNullOrEmpty(model.request?.searchText) &&
                            <Chip
                                label={"Mã đơn hàng"}
                                value={model.request?.searchText}
                                onDelete={() => {
                                    getPaged({
                                        ...model.request,
                                        searchText: undefined,
                                    });
                                }}
                            />
                        }
                        {
                            !Helpers.isNullOrEmpty(model.request?.type) &&
                            <Chip
                                label={"Loại giao dịch"}
                                value={cashBookTypeList.find(el => el.code === model.request?.type)?.name || ""}
                                onDelete={() => {
                                    getPaged({
                                        ...model.request,
                                        type: undefined,
                                    });
                                }}
                            />
                        }
                        {
                            !Helpers.isNullOrEmpty(model.request?.paymentType) &&
                            <Chip
                                label={"Phương thức"}
                                value={paymentTypeList.find(el => el.code === model.request?.paymentType)?.name || ""}
                                onDelete={() => {
                                    getPaged({
                                        ...model.request,
                                        paymentType: undefined,
                                    });
                                }}
                            />
                        }
                    </Grid>
                )
            }
        </>
    );

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization
            title={"Danh sách giao dịch"}
            route={[{ title: "Quản lý giao dịch", route: "" }]}
        >
            <Grid container spacing={2}>
                <RenderChipFilter />

                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                showFooter={false}
                                loading={loadingDataTable}
                                totalCount={model.request?.totalCount || 1}
                                pageNumber={model.request?.pageNumber || 1}
                                rowPerPage={model.request?.pageSize || Constants.ROW_PER_PAGE}
                                onChangePageSize={(pageSize) => getPaged({ ...model.request, pageSize })}
                                onChangePageNumber={(pageNumber) => getPaged({ ...model.request, pageNumber })}

                                filterForm={<RenderFilterForm />}
                                onFilter={() => {
                                    getPaged({ ...model.requestTemp, pageNumber: 1 });
                                }}
                                onReset={() => {
                                    getPaged({ pageNumber: 1 });
                                }}
                                onCloseFilter={() => {
                                    const dataTemp: any = { ...model.request };
                                    setModel({ ...model, requestTemp: dataTemp });
                                }}

                                leftElement={
                                    <>
                                        <Button
                                            color="success"
                                            onClick={exportCSV}
                                            disabled={loadingDataTable}
                                        >
                                            <FileDownloadOutlined /> &nbsp;
                                            {Strings.Common.DOWNLOAD}
                                        </Button>
                                    </>
                                }

                                //
                                table={{
                                    rows: dataRows || [],
                                    columns: [
                                        {
                                            Header: Strings.Common.ACTION, accessor: "action", width: "60px",
                                            Cell: (row) => (
                                                <IconButton color="info" onClick={() => {
                                                    navigate(`${Screens.PAYMENT_DETAIL}?id=${row.row?.original?.id}`);
                                                }}>
                                                    <InfoOutlined />
                                                </IconButton>
                                            ),
                                        },
                                        {
                                            Header: "Thời gian", accessor: "date", width: "120px",
                                            Cell: (row) => (
                                                <>
                                                    {
                                                        Helpers.getDateValue(row.value)
                                                            ? moment(Helpers.getDateValue(row.value)).format("HH:mm DD/MM/YYYY")
                                                            : "-"
                                                    }
                                                </>
                                            ),
                                        },
                                        {
                                            Header: "Mã giao dịch", accessor: "slipCode", width: "100px",
                                        },
                                        {
                                            Header: "Mã đơn hàng", accessor: "referenceCode", width: "100px",
                                        },
                                        {
                                            Header: "Tên công ty", accessor: "organizationId", width: "150px",
                                            Cell: (row) => (
                                                <>
                                                    {dataMapOrg.get(row.value)?.name?.value?.["vi"] || "-"}
                                                </>
                                            ),
                                        },
                                        {
                                            Header: "Số tiền", accessor: "amount", width: "120px",
                                            Cell: (row) => {
                                                const newTemp = getAmountCell({
                                                    amount: row.value,
                                                    type: row.row?.original?.type,
                                                    currency: row.row?.original?.currency,
                                                    slipType: row?.row?.original?.cashBookType,
                                                });
                                                return <Typography fontWeight="bold" variant="caption" color={newTemp.color as any} >{newTemp.title}</Typography>
                                            }
                                        },
                                        {
                                            Header: "Loại giao dịch", accessor: "cashBookType", width: "120px",
                                            Cell: (row) => {
                                                const newTemp = getSlipTypeCell({
                                                    slipType: row.value,
                                                    type: row.row?.original?.type,
                                                });
                                                return <Typography fontWeight="bold" variant="caption" color={newTemp.color as any} >{newTemp.title}</Typography>
                                            }
                                        },
                                        {
                                            Header: "Phương thức", accessor: "paymentMethod", width: "120px",
                                            Cell: (row) => (
                                                <>{paymentTypeList.find(el => el.code === Number(row.value))?.name || ""}</>
                                            ),
                                        },
                                        {
                                            Header: "Trạng thái", accessor: "slipStatus", width: "150px",
                                            Cell: (row) => {
                                                const newTemp = getSlipStatusCell(Number(row.value));
                                                return <Typography fontWeight="bold" variant="caption" color={newTemp.color as any} >{newTemp.title}</Typography>
                                            }
                                        },
                                    ],
                                }}
                            />
                        </Box>
                        <CustomPagination
                            isList
                            rowsPerPageOptions={[20, 50, 100]}
                            page={model.request?.pageNumber || 1}
                            total={model.request?.totalCount || 1}
                            pageSize={model.request?.pageSize || Constants.ROW_PER_PAGE}
                            onChangePage={(pageNumber) => getPaged({ ...model.request, pageNumber })}
                            onChangePageSize={(pageSize) => getPaged({ ...model.request, pageSize })}
                        />
                    </Card>
                </Grid>
            </Grid >
        </DashboardLayout >
    );
};

export default PaymentInvoiceScreen;
