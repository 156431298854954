import {
    Groups,
    Settings,
    Dashboard,
    Diversity1,
    LockPerson,
    AddHomeWork,
    AssuredWorkload,
    NewspaperOutlined,
    EventNoteOutlined,
    CreditCardOutlined,
    SupervisedUserCircleOutlined,
} from "@mui/icons-material";

import { useEffect, useState } from "react";

import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";
import { IMenuItem } from "commons/interfaces";

import RoleScreen from "screens/role";
import OrderScreen from "screens/order";
import StaffScreen from "screens/staff";
import GroupScreen from "screens/group";
import ProfileScreen from "screens/profile";
import CodenameScreen from "screens/codename";
import CustomerScreen from "screens/customer";
import DashboardScreen from "screens/dashboard";
import AttributeScreen from "screens/attribute";
import OrganizationScreen from "screens/organization";
import HotelBookingScreen from "screens/booking/hotel";
import AmendBookingScreen from "screens/booking/amend";
import FlightBookingScreen from "screens/booking/flight";
import PaymentInvoiceScreen from "screens/paymentInvoice";
import PaymentAccountScreen from "screens/paymentAccount";
import RequestDepositScreen from "screens/requestDeposit";
import ApproveDepositScreen from "screens/approveDeposit";
import ServiceFeeScreen from "screens/systemSettingCommon/serviceFee";
import PaymentInvoiceDetailScreen from "screens/paymentInvoice/detail";
import SettingLoyalCustomerScreen from "screens/systemSettingCommon/loyaltyCustomer";


export const useRenderRoute = (key: any) => {
    const [routes, setRoutes] = useState<IMenuItem[]>([]);

    useEffect(() => {
        const mainRoutes: IMenuItem[] = [
            {
                key: "dashboard",
                isVisible: true,
                icon: <Dashboard />,
                title: Strings.Home.TITLE,
                screenPath: Screens.DASHBOARD,
                screenName: <DashboardScreen />,
                resourceCode: Constants.ResourceMenu.DASHBOARD,
            },
            {
                key: "profile",
                isVisible: false,
                screenPath: Screens.PROFILE,
                screenName: <ProfileScreen />,
                title: Strings.PROFILE.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.DASHBOARD,
            },

            // Quản lý phân quyền (Authorization Management)
            {
                key: "role",
                isVisible: true,
                icon: <LockPerson />,
                iconName: "lock_person",
                screenName: <RoleScreen />,
                screenPath: Screens.ROLE_LIST,
                title: Strings.ROLE.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.ROLE,
            },
            {
                key: "role_edit",
                isVisible: false,
                screenPath: Screens.ROLE_EDIT,
                screenName: <RoleScreen />,
                title: Strings.ROLE.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceMenu.ROLE,
            },
            {
                key: "role_create",
                isVisible: false,
                screenName: <RoleScreen />,
                screenPath: Screens.ROLE_CREATE,
                title: Strings.ROLE.TITLE_CREATE_VIEW,
                resourceCode: Constants.ResourceMenu.ROLE,
            },

            // * Khách hàng
            {
                isVisible: true,
                key: "customer-module",
                icon: <SupervisedUserCircleOutlined />,
                title: Strings.CUSTOMER.TITLE_MENU,
                resourceCode: [Constants.ResourceMenu.CUSTOMER, Constants.ResourceMenu.LOYALTY_CUSTOMER],
                subMenu: [
                    {
                        key: "customer_list",
                        isVisible: true,
                        screenPath: Screens.CUSTOMER_LIST,
                        screenName: <CustomerScreen />,
                        title: Strings.CUSTOMER.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.CUSTOMER,
                    },
                    {
                        key: "customer_edit",
                        isVisible: false,
                        screenPath: Screens.CUSTOMER_EDIT,
                        screenName: <CustomerScreen />,
                        title: Strings.CUSTOMER.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.CUSTOMER,
                    },

                    {
                        key: "loyalty_member",
                        isVisible: true,
                        screenName: <SettingLoyalCustomerScreen />,
                        screenPath: Screens.SETTING_LOYALTY_CUSTOMER,
                        title: Strings.LOYALTY_CUSTOMER.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.LOYALTY_CUSTOMER,
                    },
                ],
            },

            // Quản lý tổ chức
            {
                isVisible: true,
                icon: <AddHomeWork />,
                iconName: "add_home_work",
                key: "organization-module",
                title: Strings.ORGANIZATION.TITLE_MENU_PARENT,
                resourceCode: [Constants.ResourceMenu.ORGANIZATION, Constants.ResourceMenu.GROUP, Constants.ResourceMenu.STAFF],
                subMenu: [
                    // ORGANIZATION
                    {
                        key: "organization",
                        isVisible: true,
                        icon: <AssuredWorkload />,
                        iconName: "assured_workload",
                        screenName: <OrganizationScreen />,
                        screenPath: Screens.ORGANIZATION_LIST,
                        title: Strings.ORGANIZATION.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.ORGANIZATION,
                    },
                    {
                        key: "organization_edit",
                        isVisible: false,
                        screenPath: Screens.ORGANIZATION_EDIT,
                        screenName: <OrganizationScreen />,
                        title: Strings.ORGANIZATION.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.ORGANIZATION,
                    },
                    {
                        key: "organization_create",
                        isVisible: false,
                        screenName: <OrganizationScreen />,
                        screenPath: Screens.ORGANIZATION_CREATE,
                        title: Strings.ORGANIZATION.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.ORGANIZATION,
                    },

                    // GROUP
                    {
                        key: "group",
                        isVisible: true,
                        icon: <Diversity1 />,
                        iconName: "diversity_1",
                        screenName: <GroupScreen />,
                        screenPath: Screens.GROUP_LIST,
                        title: Strings.GROUP.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.GROUP,
                    },
                    {
                        key: "group_edit",
                        isVisible: false,
                        screenPath: Screens.GROUP_EDIT,
                        screenName: <GroupScreen />,
                        title: Strings.GROUP.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.GROUP,
                    },
                    {
                        key: "group_create",
                        isVisible: false,
                        screenName: <GroupScreen />,
                        screenPath: Screens.GROUP_CREATE,
                        title: Strings.GROUP.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.GROUP,
                    },

                    // STAFF
                    {
                        key: "staff",
                        isVisible: true,
                        icon: <Groups />,
                        iconName: "groups",
                        screenName: <StaffScreen />,
                        screenPath: Screens.STAFF_LIST,
                        title: Strings.STAFF.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.STAFF,
                    },
                    {
                        key: "staff_edit",
                        isVisible: false,
                        screenName: <StaffScreen />,
                        screenPath: Screens.STAFF_EDIT,
                        title: Strings.STAFF.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.STAFF,
                    },
                    {
                        key: "staff_create",
                        isVisible: false,
                        screenName: <StaffScreen />,
                        screenPath: Screens.STAFF_CREATE,
                        title: Strings.STAFF.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.STAFF,
                    },
                ],
            },

            // * Yêu cầu đặt chỗ
            {
                key: "booking-module",
                isVisible: true,
                icon: <EventNoteOutlined />,
                title: Strings.BOOKING.TITLE_MENU,
                resourceCode: [Constants.ResourceMenu.BOOKING_FLIGHT, Constants.ResourceMenu.BOOKING_HOTEL, Constants.ResourceMenu.AMEND],
                subMenu: [
                    {
                        key: "amend_list",
                        isVisible: true,
                        screenName: <AmendBookingScreen />,
                        screenPath: Screens.AMEND_BOOKING_LIST,
                        title: Strings.BOOKING.TITLE_MENU_AMEND,
                        resourceCode: Constants.ResourceMenu.AMEND,
                    },
                    {
                        key: "amend_detail",
                        isVisible: false,
                        screenName: <AmendBookingScreen />,
                        screenPath: Screens.AMEND_BOOKING_VIEW,
                        title: Strings.BOOKING.TITLE_MENU_AMEND,
                        resourceCode: Constants.ResourceMenu.AMEND,
                    },
                    {
                        key: "flight_list",
                        isVisible: true,
                        screenName: <FlightBookingScreen />,
                        screenPath: Screens.FLIGHT_BOOKING_LIST,
                        title: Strings.BOOKING.TITLE_MENU_FLIGHT,
                        resourceCode: Constants.ResourceMenu.BOOKING_FLIGHT,
                    },
                    {
                        key: "flight_detail",
                        isVisible: false,
                        screenName: <FlightBookingScreen />,
                        screenPath: Screens.FLIGHT_BOOKING_VIEW,
                        title: Strings.BOOKING.TITLE_MENU_FLIGHT,
                        resourceCode: Constants.ResourceMenu.BOOKING_FLIGHT,
                    },

                    {
                        key: "hotel_list",
                        isVisible: true,
                        screenName: <HotelBookingScreen />,
                        screenPath: Screens.HOTEL_BOOKING_LIST,
                        title: Strings.BOOKING.TITLE_MENU_HOTEL,
                        resourceCode: Constants.ResourceMenu.BOOKING_HOTEL,
                    },
                    {
                        key: "hotel_detail",
                        isVisible: false,
                        screenName: <HotelBookingScreen />,
                        screenPath: Screens.HOTEL_BOOKING_VIEW,
                        title: Strings.BOOKING.TITLE_MENU_HOTEL,
                        resourceCode: Constants.ResourceMenu.BOOKING_HOTEL,
                    },
                    // {
                    //     key: "hotel_create",
                    //     isVisible: false,
                    //     screenName: <HotelBookingScreen />,
                    //     screenPath: Screens.BOOKING_CREATE,
                    //     title: Strings.BOOKING.TITLE_CREATE,
                    //     resourceCode: Constants.ResourceMenu.BOOKING_HOTEL,
                    // },
                    // {
                    //     key: "hotel_edit",
                    //     isVisible: false,
                    //     screenName: <HotelBookingScreen />,
                    //     screenPath: Screens.BOOKING_EDIT,
                    //     title: Strings.BOOKING.TITLE_UPDATE,
                    //     resourceCode: Constants.ResourceMenu.BOOKING_HOTEL,
                    // },
                ],
            },

            // * Đơn hàng
            {
                key: "order",
                isVisible: true,
                icon: <NewspaperOutlined />,
                screenName: <OrderScreen />,
                screenPath: Screens.ORDER_LIST,
                title: Strings.ORDER.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.ORDER,
            },
            {
                key: "order_edit",
                isVisible: false,
                screenName: <OrderScreen />,
                screenPath: Screens.ORDER_EDIT,
                title: Strings.ORDER.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceMenu.ORDER,
            },
            {
                key: "order_detail",
                isVisible: false,
                screenName: <OrderScreen />,
                screenPath: Screens.ORDER_VIEW,
                title: Strings.ORDER.TITLE_DETAIL_VIEW,
                resourceCode: Constants.ResourceMenu.ORDER,
            },

            // * Phiếu thanh toán
            {
                key: "payment",
                isVisible: true,
                icon: <NewspaperOutlined />,
                title: "Quản lý giao dịch",
                screenPath: Screens.PAYMENT_LIST,
                screenName: <PaymentInvoiceScreen />,
                resourceCode: Constants.ResourceMenu.CASHBOOK,
            },
            {
                key: "payment_detail",
                isVisible: false,
                title: "Quản lý giao dịch",
                screenPath: Screens.PAYMENT_DETAIL,
                screenName: <PaymentInvoiceDetailScreen />,
                resourceCode: Constants.ResourceMenu.CASHBOOK,
            },

            // * System Setting Common
            {
                key: "system-setting",
                isVisible: true,
                icon: <Settings />,
                title: Strings.SYSTEM_SETTING.TITLE_MENU,
                resourceCode: [Constants.ResourceMenu.CODENAME, Constants.ResourceMenu.SERVICE_FEE, Constants.ResourceMenu.ATTRIBUTE],
                subMenu: [
                    // attribute
                    {
                        key: "attribute",
                        isVisible: true,
                        screenPath: Screens.ATTRIBUTE_LIST,
                        title: Strings.ATTRIBUTE.TITLE_MENU,
                        screenName: <AttributeScreen />,
                        resourceCode: Constants.ResourceMenu.ATTRIBUTE,
                    },
                    {
                        key: "attribute_edit",
                        isVisible: false,
                        screenPath: Screens.ATTRIBUTE_EDIT,
                        title: Strings.ATTRIBUTE.TITLE_MENU,
                        screenName: <AttributeScreen />,
                        resourceCode: Constants.ResourceMenu.ATTRIBUTE,
                    },

                    // Code Name
                    {
                        key: "codename_list",
                        isVisible: true,
                        title: Strings.Codename.TITLE_MENU,
                        screenPath: Screens.CODENAME_LIST,
                        screenName: <CodenameScreen />,
                        resourceCode: Constants.ResourceMenu.CODENAME,
                    },
                    {
                        key: "codename_edit",
                        isVisible: false,
                        title: Strings.Codename.TITLE_MENU,
                        screenPath: Screens.CODENAME_EDIT,
                        screenName: <CodenameScreen />,
                        resourceCode: Constants.ResourceMenu.CODENAME,
                    },

                    {
                        key: "service_fee_list",
                        isVisible: true,
                        title: Strings.SERVICE_FEE.TITLE_MENU,
                        screenPath: Screens.SERVICE_FEE_LIST,
                        screenName: <ServiceFeeScreen />,
                        resourceCode: Constants.ResourceMenu.SERVICE_FEE,
                    },
                ],
            },
            {
                key: "ticket-fund",
                isVisible: true,
                icon: <CreditCardOutlined />,
                title: Strings.TICKET_FUND.TITLE_MENU,
                resourceCode: [Constants.ResourceMenu.TICKET_FUND_APPROVAL, Constants.ResourceMenu.TICKET_FUND_REQUEST, Constants.ResourceMenu.PAYMENT_ACCOUNT],
                subMenu: [
                    // attribute
                    {
                        key: "limit-setting",
                        isVisible: true,
                        screenPath: Screens.LIMIT_SETTING_LIST,
                        title: Strings.LIMIT_SETTING.TITLE_MENU,
                        screenName: <PaymentAccountScreen />,
                        resourceCode: Constants.ResourceMenu.PAYMENT_ACCOUNT,
                    },
                    {
                        key: "limit-setting_edit",
                        isVisible: false,
                        screenPath: Screens.LIMIT_SETTING_EDIT,
                        title: Strings.LIMIT_SETTING.TITLE_MENU,
                        screenName: <PaymentAccountScreen />,
                        resourceCode: Constants.ResourceMenu.PAYMENT_ACCOUNT,
                    },
                    {
                        key: "request-deposit",
                        isVisible: true,
                        screenPath: Screens.REQUEST_DEPOSIT_LIST,
                        title: Strings.REQUEST_DEPOSIT.TITLE_MENU,
                        screenName: <RequestDepositScreen />,
                        resourceCode: Constants.ResourceMenu.TICKET_FUND_REQUEST,
                    },
                    {
                        key: "request-deposit_edit",
                        isVisible: false,
                        screenPath: Screens.REQUEST_DEPOSIT_EDIT,
                        title: Strings.REQUEST_DEPOSIT.TITLE_MENU,
                        screenName: <RequestDepositScreen />,
                        resourceCode: Constants.ResourceMenu.TICKET_FUND_REQUEST,
                    },
                    {
                        key: "approve-deposit",
                        isVisible: true,
                        screenPath: Screens.APPROVE_DEPOSIT_LIST,
                        title: Strings.APPROVE_DEPOSIT.TITLE_MENU,
                        screenName: <ApproveDepositScreen />,
                        resourceCode: Constants.ResourceMenu.TICKET_FUND_APPROVAL,
                    },
                    {
                        key: "approve-deposit_edit",
                        isVisible: false,
                        screenPath: Screens.APPROVE_DEPOSIT_EDIT,
                        title: Strings.APPROVE_DEPOSIT.TITLE_MENU,
                        screenName: <ApproveDepositScreen />,
                        resourceCode: Constants.ResourceMenu.TICKET_FUND_APPROVAL,
                    },
                ],
            },
        ];
        setRoutes(mainRoutes);
    }, [key]);
    return routes;
};
