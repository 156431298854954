import Constants from "constants/index";
import BaseService from "./base.service";
import Helpers from "commons/helpers";

class TransportHubService extends BaseService {
    async getPaged(data: {
        type?: number;
        countryCode?: string;
        selectedCodes?: string[];
        searchText?: string;
        pageNumber?: number;
        pageSize?: number;
    }) {
        const result = await this.api.get({
            path: Constants.ApiPath.TRANSPORT_HUB.GET_PAGED.concat(`?${Helpers.handleFormatParams(data)}`),
        });
        return result.data.result;
    }
};

export default TransportHubService;