import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Card, Grid, IconButton } from "@mui/material";
import { FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import {
    Autocomplete,
    Box, Button,
    Chip, DataTable,
    IPropListContainer,
    DataTableStatusCell,
    FormField, Typography,
    useCommonComponentContext,
    DatePicker,
    CustomPagination
} from "@maysoft/common-component-react";

import { Mode } from "constants/enum";
import Constants from "constants/index";
import Strings from "constants/strings";
import Helpers from "../../commons/helpers";
import useDataOrder from "./useDataOrder.hook";
import useDataRequestBooking from "components/Booking/useDataRequestBooking.hook";
import OrderService, { IRecordOrder, IReqGetPagedOrder } from "services/sale/order.service";



interface IModel {
    requestData?: IReqGetPagedOrder;
    requestDataTemp?: IReqGetPagedOrder;
}

const OrderListContainer = (props: IPropListContainer<IReqGetPagedOrder>
    & {
        hidenActionExport?: boolean;
    }
) => {

    const {
        getDataMapOrganizationByListID,
    } = useDataRequestBooking();

    const {
        getPaymentStatus,
        paymentStatusList,
        listOrderDetailType,
    } = useDataOrder();

    const {
        userInfo,
        onError,
        onShowLoading,
        onHideLoading,
    } = useCommonComponentContext();

    const [model, setModel] = useState<IModel>({});
    const [dataRows, setDataRows] = useState<IRecordOrder[]>([]);
    const [dataOrg, setDataOrg] = useState<Map<string, any>>(new Map());
    const [loadingDataTable, setLoadingDataTable] = useState<boolean>(false);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile?.organizationId)) {
            getPaged({
                toDate: props.requestGetPaged?.toDate || model?.requestData?.toDate,
                fromDate: props.requestGetPaged?.fromDate || model?.requestData?.fromDate,
                searchText: props.requestGetPaged?.searchText || model?.requestData?.searchText,
                partnerName: props.requestGetPaged?.partnerName || model?.requestData?.partnerName,
                orderStatus: props.requestGetPaged?.orderStatus || model?.requestData?.orderStatus,
                paymentStatus: props.requestGetPaged?.paymentStatus || model?.requestData?.paymentStatus,
                orderDetailType: props.requestGetPaged?.orderDetailType || model?.requestData?.orderDetailType,

                pageSize: props.requestGetPaged?.pageSize || model?.requestData?.pageSize,
                pageNumber: props.requestGetPaged?.pageNumber || model?.requestData?.pageNumber,
                totalCount: props.requestGetPaged?.totalCount || model?.requestData?.totalCount,
            });
        } else {
            setDataRows([]);
        }
    }, [props.requestGetPaged, userInfo?.userProfile?.organizationId]);

    const getPaged = async (req: IReqGetPagedOrder) => {
        try {
            // onShowLoading();
            setLoadingDataTable(true);

            const pageSize = req?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(req?.pageNumber || 1, pageSize, req?.totalCount || 0);

            let newRequestData: any = {
                pageSize,
                pageNumber,

                searchText: req?.searchText,
                partnerName: req?.partnerName,
                orderStatus: req?.orderStatus,
                paymentStatus: req?.paymentStatus,
                orderDetailType: req?.orderDetailType,
                toDate: req?.toDate ?? moment().endOf("month").unix(),
                fromDate: req?.fromDate ?? moment().startOf("month").unix(),

                organizationId: req?.organizationId || userInfo?.userProfile?.organizationId || "0",
            };

            const result = await new OrderService().getPaged(newRequestData);

            newRequestData["totalCount"] = result.totalCount || 0;

            setDataRows(result.items);

            setModel(prev => ({
                ...prev,
                requestData: newRequestData,
                requestDataTemp: newRequestData,
            }));

            const idOrgs = [...result.items || []].map(el => el.organizationId);
            const newDataOrg = await getDataMapOrganizationByListID(idOrgs);
            setDataOrg(newDataOrg);

            let query: string = `?pageNumber=${newRequestData?.pageNumber || 1}&pageSize=${newRequestData.pageSize || 20}`;
            if (!Helpers.isNullOrEmpty(newRequestData?.searchText)) {
                query += `&searchText=${newRequestData?.searchText}`;
            }
            if (!Helpers.isNullOrEmpty(newRequestData?.fromDate)) {
                query += `&fromDate=${newRequestData?.fromDate}`;
            }
            if (!Helpers.isNullOrEmpty(newRequestData?.toDate)) {
                query += `&toDate=${newRequestData?.toDate}`;
            }
            if (!Helpers.isNullOrEmpty(newRequestData?.orderStatus)) {
                query += `&orderStatus=${newRequestData?.orderStatus}`;
            }
            if (!Helpers.isNullOrEmpty(newRequestData?.paymentStatus)) {
                query += `&paymentStatus=${newRequestData?.paymentStatus}`;
            }
            if (!Helpers.isNullOrEmpty(newRequestData?.partnerName)) {
                query += `&partnerName=${newRequestData?.partnerName}`;
            }
            if (!Helpers.isNullOrEmpty(newRequestData?.orderDetailType)) {
                query += `&orderDetailType=${newRequestData?.orderDetailType}`;
            }

            props.onGetPaged({ query, totalCount: result.totalCount });

        } catch (error) {
            const err = Helpers.renderExceptionError(error);
            onError(err);
        } finally {
            // onHideLoading();
            setLoadingDataTable(false);
        }
    };

    const exportCSV = async () => {
        Helpers.showConfirmAlert("Bạn chắc chắn muốn tải file về", async () => {
            try {
                onShowLoading();

                const result = await new OrderService().exportCsv({ ...model?.requestData, totalCount: undefined });

                const date = moment().format("HH_mm_DD_MM_YYYY");

                var fileName = `Flight_Booking_${date}.csv`;
                const downloadUrl = URL.createObjectURL(result);
                const a = document.createElement("a");

                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

            } catch (error) {
                const e = Helpers.renderExceptionError(error);
                onError(e);
            } finally {
                onHideLoading();
            }
        });
    };

    // #region Filter Form
    const RenderFilterForm = () => (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DatePicker
                        label={"Thời gian"}
                        views={["month", "year"]}
                        placeholder={"Chọn thời gian"}
                        value={Helpers.getDateValue(model?.requestDataTemp?.fromDate)}
                        onChangeValue={(value: any) => {
                            const valStartTime = !Helpers.isNullOrEmpty(value) ? moment(value).startOf("month").unix() : undefined;

                            const valEndTime = !Helpers.isNullOrEmpty(value) ? moment(value).endOf("month").unix() : undefined;

                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    toDate: valEndTime,
                                    fromDate: valStartTime,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        maxLength={255}
                        label={"Tên công ty"}
                        placeholder={"Nhập tên công ty"}
                        defaultValue={model?.requestDataTemp?.partnerName || ""}
                        onBlur={(value) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    partnerName: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        maxLength={255}
                        label={"Mã đơn hàng"}
                        placeholder={Strings.ORDER.ENTER_SEARCH_TEXT}
                        defaultValue={model?.requestDataTemp?.searchText || ""}
                        onBlur={(value) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    searchText: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Dịch vụ"}
                        placeholder={"Chọn dịch vụ"}
                        data={listOrderDetailType || []}
                        defaultValue={model?.requestDataTemp?.orderDetailType}
                        onChange={(value) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    orderDetailType: value,
                                },
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Trạng thái"}
                        data={paymentStatusList || []}
                        placeholder={"Chọn trạng thái"}
                        defaultValue={model?.requestDataTemp?.paymentStatus}
                        onChange={(value) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    paymentStatus: value,
                                },
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
    // #endregion Filter Form

    // #region Chip Value Filter
    const RenderChipValueFilter = () => (
        <>
            {(
                !Helpers.isNullOrEmpty(model?.requestData?.toDate) ||
                !Helpers.isNullOrEmpty(model?.requestData?.fromDate) ||
                !Helpers.isNullOrEmpty(model?.requestData?.searchText) ||
                !Helpers.isNullOrEmpty(model?.requestData?.partnerName) ||
                !Helpers.isNullOrEmpty(model?.requestData?.orderStatus) ||
                !Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) ||
                !Helpers.isNullOrEmpty(model?.requestData?.orderDetailType)
            ) &&
                <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                    {!Helpers.isNullOrEmpty(model?.requestData?.fromDate) &&
                        !Helpers.isNullOrEmpty(model?.requestData?.toDate) &&
                        <Chip
                            label={"Thời gian"}
                            value={[
                                moment(Helpers.getDateValue(model?.requestData?.fromDate)).format("DD/MM/YYYY"),
                                moment(Helpers.getDateValue(model?.requestData?.toDate)).format("DD/MM/YYYY")
                            ].join(" - ")}
                            onDelete={() => {
                                getPaged({
                                    ...model.requestData,
                                    toDate: moment().endOf("month").unix(),
                                    fromDate: moment().startOf("month").unix(),
                                });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.partnerName) &&
                        <Chip
                            label={"Tên công ty"}
                            value={model?.requestData?.partnerName}
                            onDelete={() => {
                                getPaged({ ...model.requestData, partnerName: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.searchText) &&
                        <Chip
                            label={"Mã đơn hàng"}
                            value={model?.requestData?.searchText}
                            onDelete={() => {
                                getPaged({ ...model.requestData, searchText: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) &&
                        <Chip
                            label={"Trạng thái"}
                            value={[...paymentStatusList || []].find(el => el.code === model?.requestData?.paymentStatus)?.name || ""}
                            onDelete={() => {
                                getPaged({ ...model.requestData, paymentStatus: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.orderDetailType) &&
                        <Chip
                            label={"Dịch vụ"}
                            value={[...listOrderDetailType || []].find(el => el.code === model?.requestData?.orderDetailType)?.name || ""}
                            onDelete={() => {
                                getPaged({ ...model.requestData, orderDetailType: undefined });
                            }}
                        />
                    }
                </Box>
            }
        </>
    );
    // #endregion Chip Value Filter

    const dataColumns = useMemo(() => (
        [
            {
                Header: Strings.Common.TIME, accessor: "orderDate", width: "120px",
                Cell: (row: any) => {
                    const newValue = Helpers.getDateValue(row?.value);
                    return (
                        <Box display="grid">
                            <Typography variant="caption" fontWeight="bold" color="secondary">
                                {newValue ? moment(newValue).format("HH:mm DD/MM/YYYY") : "-"}
                            </Typography>
                        </Box>
                    )
                },
            },
            {
                Header: Strings.ORDER.ORDER_CODE, accessor: "orderCode", width: "90px",
            },
            {
                Header: "Dịch vụ", accessor: "orderDetailType", width: "150px",
                Cell: (row: any) => (
                    <>{listOrderDetailType.find(el => el.code === row.value)?.name || "-"}</>
                ),
            },
            {
                Header: "Tên công ty", accessor: "organizationId", width: "200px",
                Cell: (row: any) => <>{dataOrg.get(row.value)?.name || "-"}</>
            },
            {
                Header: "Số tiền", accessor: "amount", width: "150px",
                Cell: (row: any) => {
                    const color = getPaymentStatus(row?.row?.original?.paymentStatus)?.color;
                    const currency = row?.row?.original?.currency || Constants.CURRENCY_DEFAULT;

                    return (
                        <Typography variant="caption" fontWeight="bold" color={color}>
                            {`${(color === "error") ? "-" : ""} ${Helpers.formatCurrency(row?.value || 0)} ${currency}`}
                        </Typography>
                    );
                },
            },
            {
                Header: Strings.Common.STATUS, accessor: "paymentStatus", width: "150px",
                Cell: (row: any) => (
                    <DataTableStatusCell data={getPaymentStatus(row.value) as any} />
                )
            },
            {
                Header: Strings.Common.ACTION, accessor: "action", width: "60px",
                Cell: (row: any) => (
                    <IconButton color="info" onClick={() => {
                        props?.onNavigate({ id: row?.row?.original?.id, mode: Mode.View });
                    }}>
                        <InfoOutlined />
                    </IconButton>
                ),
            },
        ]
    ), [Strings, dataOrg, getPaymentStatus, listOrderDetailType,]);

    return (
        <>
            <Box>
                <RenderChipValueFilter />
                <Card>
                    <Box p={2}>
                        <DataTable
                            showFooter={false}
                            loading={loadingDataTable}
                            rowPerPageOptions={[20, 50, 100]}
                            rowPerPage={model?.requestData?.pageSize}
                            pageNumber={model?.requestData?.pageNumber}
                            totalCount={model?.requestData?.totalCount}
                            onChangePageSize={(pageSize) => {
                                getPaged({ ...model?.requestData, pageSize });
                            }}
                            onChangePageNumber={(pageNumber) => {
                                getPaged({ ...model?.requestData, pageNumber });
                            }}

                            filterForm={<RenderFilterForm />}
                            onFilter={() => {
                                getPaged({ ...model?.requestDataTemp, pageNumber: 1 });
                            }}
                            onReset={() => {
                                getPaged({
                                    pageNumber: 1,
                                    pageSize: Constants.ROW_PER_PAGE,
                                });
                            }}
                            onCloseFilter={() => {
                                const newData = { ...model.requestData };
                                setModel({
                                    ...model,
                                    requestDataTemp: newData
                                });
                            }}

                            table={{
                                columns: dataColumns,
                                rows: dataRows || [],
                            }}

                            leftElement={
                                <>
                                    {
                                        !props.hidenActionExport &&
                                        <Button
                                            color="success"
                                            disabled={loadingDataTable}
                                            onClick={() => { exportCSV(); }}
                                        >
                                            <FileDownloadOutlined /> &nbsp; {Strings.Common.DOWNLOAD}
                                        </Button>
                                    }
                                </>
                            }
                        />
                        <CustomPagination
                            isList
                            rowsPerPageOptions={[20, 50, 100]}
                            page={model?.requestData?.pageNumber}
                            pageSize={model?.requestData?.pageSize}
                            total={model?.requestData?.totalCount}
                            onChangePage={(value) => {
                                getPaged({ ...model.requestData, pageNumber: value });
                            }}
                            onChangePageSize={(value) => {
                                getPaged({ ...model.requestData, pageSize: value });
                            }}
                        />
                    </Box>
                </Card>
            </Box>
        </>
    );
};

export default OrderListContainer;
