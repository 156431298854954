import { Card, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@maysoft/common-component-react";

import Strings from "constants/strings";
import CardStatusInfo from "./cardStatusInfo";
import CardPaymentInfo from "./cardPaymentInfo";
import CardListMemberInfo from "./cardListMemberInfo";
import CardItemFlightInfo from "./cardItemFlightInfo";
import OrderService from "services/sale/order.service";
import CardOperationHistory from "./cardOperationHistory";
import AddMoreBookingServiceProvider from "providers/addBookingServiceProvider";

import { IOrder } from "commons/interfaces";
import { BookingDetailAmendType, ConfirmStatus, PaymentStatus } from "constants/enum";
import useDataRequestBooking, { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";



interface IProps {
    onGoBack: () => void;
    dataDetail?: IDetailBooking;
    refetchDetail: () => Promise<void>;
}

const orderService = new OrderService();
const FlightBookingDetailContainer = ({ dataDetail, onGoBack, refetchDetail }: IProps) => {

    const {
        getDataMapUserProfileByListID,
    } = useDataRequestBooking();

    const [dataUserProfile, setDataUserProfile] = useState<Map<string, any>>(new Map());

    const [bookingOrderDetail, setBookingOrderDetail] = useState<IOrder | undefined>(undefined);
    const [isBookingOrderDetailLoading, setIsBookingOrderDetailLoading] = useState<boolean>(true);

    const getOrderDetail = useCallback(async (orderId?: string) => {
        if (orderId) {
            try {
                setIsBookingOrderDetailLoading(true);
                const response = await orderService.getDetail(orderId);
                setBookingOrderDetail(response as any);
            } catch (e) {
                console.error(e);
            } finally {
                setIsBookingOrderDetailLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        getOrderDetail(dataDetail?.bookingOrderId);
    }, [dataDetail?.bookingOrderId, getOrderDetail]);

    useEffect(() => {
        (async () => {

            if ([...dataDetail?.members || []].length > 0) {
                const result = await getDataMapUserProfileByListID(dataDetail?.members, dataDetail?.organizationId);

                setDataUserProfile(result);
            }

        })()
    }, [dataDetail?.members, dataDetail?.organizationId]);

    const newBookingDetails = useMemo(() => (
        [...dataDetail?.bookingDetails || []].filter(el => el.amendType === 0) || []
    ), [dataDetail?.bookingDetails]);

    const bookingUsers = useMemo(() => (
        Array.from(dataUserProfile.values())
    ), [dataUserProfile]);

    const serviceFee = bookingOrderDetail?.serviceFee || dataDetail?.serviceFee || 0;
    const serviceFeeTax = serviceFee / 11;
    const rawServiceFee = serviceFee - serviceFeeTax;

    return (
        <AddMoreBookingServiceProvider
            booking={dataDetail}
            users={bookingUsers}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button color={"secondary"} onClick={() => { onGoBack(); }}>
                            {Strings.Common.GO_BACK}
                        </Button>
                    </Box>
                </Grid>

                {/* Status Information */}
                <Grid item xs={12}>
                    <CardStatusInfo data={dataDetail} refetchDetail={async (args) => {
                        if (args?.needRefetchBookingOrder) {
                            await Promise.all([
                                getOrderDetail(dataDetail?.bookingOrderId),
                                refetchDetail(),
                            ])
                        } else {
                            refetchDetail();
                        }
                    }} isBookingOrderLoading={isBookingOrderDetailLoading} order={bookingOrderDetail} />
                </Grid>

                {/* Itinerary Information */}
                <Grid item xs={12}>
                    <Card>
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Typography variant="h6">{`Thông tin hành trình (${newBookingDetails?.length})`}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        [...newBookingDetails || []].map(item => (
                                            <CardItemFlightInfo
                                                data={item}
                                                key={item?.id}
                                                dataUserProfile={dataUserProfile}
                                                isShowReservationCode={
                                                    ((
                                                        (Number(dataDetail?.paymentStatus) >= PaymentStatus.Completed) &&
                                                        Number(dataDetail?.paymentStatus) < PaymentStatus.WaitingRefund
                                                    ) &&
                                                        (Number(dataDetail?.confirmStatus) === ConfirmStatus.Completed))
                                                }
                                            />
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>

                {/* Member Information */}
                <Grid item xs={12}>
                    <CardListMemberInfo dataUserProfile={dataUserProfile} />
                </Grid>

                {/* Payment Information */}
                <Grid item xs={12}>
                    <CardPaymentInfo
                        data={{
                            currency: dataDetail?.currency,
                            members: dataDetail?.members || [],
                            paymentFee: bookingOrderDetail?.paymentFee || 0,
                            serviceFee: rawServiceFee || 0,
                            serviceFeeTax: serviceFeeTax || 0,
                            totalAmount: bookingOrderDetail?.amount || (dataDetail?.totalAmount + serviceFee) || 0,
                        }}
                    />
                </Grid>

                {/* Operations*/}
                {dataDetail && dataDetail.bookingDetails.some(d => d.amendType !== BookingDetailAmendType.Normal) && dataUserProfile.size && (
                    <Grid item xs={12}>
                        <CardOperationHistory />
                    </Grid>
                )}
            </Grid>
        </AddMoreBookingServiceProvider>
    );
};

export default FlightBookingDetailContainer;