import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Screens from "constants/screens";
import Constants from "../../constants";

import useModule from "hooks/useModule";
import { DashboardLayout } from "layout";
import { TicketFundModule } from "@maysoft/sale-common-react";

const ApproveDepositScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        startTime: searchParams.get("startTime"),
        endTime: searchParams.get("endTime"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.APPROVE_DEPOSIT_LIST,
            detail: Screens.APPROVE_DEPOSIT_EDIT,
        },
        keyOfStrings: "APPROVE_DEPOSIT",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <TicketFundModule
                {...moduleProps}
                isRequest
                handleExport={async () => {
                    return;
                }}
            />
        </DashboardLayout>
    );
};
export default ApproveDepositScreen;
