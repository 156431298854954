import Strings from "constants/strings";
import { ICodename } from "commons/interfaces";
import { OrderDetailType, OrderStatus, PaymentStatus, PaymentType } from "constants/enum";


const useDataOrder = () => {

    const listOrderDetailType: ICodename[] = [
        { code: OrderDetailType.Hotel, name: "Khách sạn", },
        { code: OrderDetailType.Flight, name: "Chuyến bay", },
    ];

    const listOrderStatus: ICodename[] = [
        { code: OrderStatus.Cancel, name: "Đã hủy", },
        { code: OrderStatus.Pending, name: "Chờ xử lý", },
        { code: OrderStatus.New, name: "Mới", },
        { code: OrderStatus.Confirm, name: "Đã xác nhận", },
        { code: OrderStatus.Editing, name: "Đã chỉnh sửa", },
        { code: OrderStatus.PaymentConfirm, name: "Đã thanh toán", },
        { code: OrderStatus.DebtConfirm, name: "Xác nhận nợ", },
        { code: OrderStatus.ExchangeRequest, name: "Yêu cầu đổi trả", },
        { code: OrderStatus.ExchangeCancel, name: "Hủy bỏ đổi trả", },
        { code: OrderStatus.Completed, name: "Hoàn thành", },
    ];

    const paymentStatusList: ICodename[] = [
        {
            code: PaymentStatus.Cancel,
            name: Strings.STATUS.PAYMENT.Cancel,
        },
        {
            code: PaymentStatus.New,
            name: Strings.STATUS.PAYMENT.Pending,
        },
        {
            code: PaymentStatus.WaitingConfirm,
            name: Strings.STATUS.PAYMENT.WaitingConfirm,
        },
        {
            code: PaymentStatus.Completed,
            name: Strings.STATUS.PAYMENT.Completed,
        },
        {
            code: PaymentStatus.WaitingRefund,
            name: Strings.STATUS.PAYMENT.WaitingRefund,
        },
        {
            code: PaymentStatus.Refunded,
            name: Strings.STATUS.PAYMENT.Refunded,
        },
    ];

    const paymentTypeList: ICodename[] = [
        {
            code: PaymentType.Debt,
            name: "Quỹ xuất vé",
        },
        {
            code: PaymentType.NinePayATM,
            name: "Thẻ nội địa",
        },
        {
            code: PaymentType.NinePayCollection,
            name: "QR code",
        },
        {
            code: PaymentType.NinePayCredit,
            name: "Thẻ quốc tệ tại VN",
        },
        {
            code: PaymentType.NinePayCreditInternational,
            name: "Thẻ quốc tế ngoài VN",
        },
    ];

    const getPaymentStatus = (paymentStatus: number) => {
        let newData: any = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };

        switch (paymentStatus) {
            case PaymentStatus.WaitingConfirm:
                newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                break;
            case PaymentStatus.New:
                newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                break;
            case PaymentStatus.Pending:
                newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                break;

            case PaymentStatus.Completed:
                newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                break;
            case PaymentStatus.Failed:
                newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                break;
            case PaymentStatus.Issued:
                newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                break;

            case PaymentStatus.WaitingRefund:
                newData = { icon: "currency_exchange", color: "info", title: Strings.STATUS.PAYMENT.WaitingRefund };
                break;

            case PaymentStatus.Refunded:
                newData = { icon: "currency_exchange", color: "error", title: Strings.STATUS.PAYMENT.Refunded };
                break;

            case PaymentStatus.Cancel:
                newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
                break;

            default:
                newData = { icon: "", color: "secondary", title: "Không xác định" };
                break;
        };

        return newData;
    };

    return {
        paymentTypeList,
        listOrderStatus,
        paymentStatusList,
        listOrderDetailType,
        getPaymentStatus,
    };
};

export default useDataOrder;