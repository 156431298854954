import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Box, Button, IPropDetailContainer, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import CardBasicOrder from "./cardBasicOrder";
import CardPaymentOrder from "./cardPaymentOrder";
import CardCustomerOrder from "./cardCustomerOrder";
import CardHistoriesOrder from "./cardHistoriesOrder";

import OrderService, { IRecordOrder } from "services/sale/order.service";
import useDataOrder from "../useDataOrder.hook";



const OrderDetailContainer = (props: IPropDetailContainer) => {

    const {
        userInfo,
        onError,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const { listOrderStatus, paymentTypeList, getPaymentStatus } = useDataOrder();

    const [dataDetail, setDataDetail] = useState<IRecordOrder | undefined>(undefined);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile.organizationId) &&
            !Helpers.isNullOrEmpty(props.idDetail)) {
            getDetailOrder(props.idDetail);
        } else {
            props.onGoBack();
        }
    }, [userInfo?.userProfile.organizationId, props.idDetail]);

    const getDetailOrder = async (id: string) => {
        try {
            onShowLoading();

            const result = await new OrderService().getDetail(id);

            setDataDetail(result);

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            onHideLoading();
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button color={"secondary"} onClick={() => { props.onGoBack(); }}>
                            {Strings.Common.GO_BACK}
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={7} lg={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CardBasicOrder
                                data={dataDetail}
                                titleStatus={getPaymentStatus(dataDetail?.paymentStatus)?.title || ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CardPaymentOrder
                                data={dataDetail}
                                paymentTypeList={paymentTypeList}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <CardCustomerOrder organization={dataDetail?.organization} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} lg={12}>
                            <CardHistoriesOrder
                                listOrderStatus={listOrderStatus}
                                dataOrderHistories={dataDetail?.orderHistories || []}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default OrderDetailContainer;
