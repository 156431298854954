import moment from "moment";
import { useMemo } from "react";
import { Card, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { Box, DataTable, DataTableBodyCell, DataTableHeadCell, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import { OrderType, PaymentStatus } from "constants/enum";



const CardBasicOrder = ({ data, titleStatus }: {
    data: any;
    titleStatus: string;
}) => {

    const isCheck = useMemo(() => (
        [PaymentStatus.Refunded, PaymentStatus.WaitingRefund].includes(data?.paymentStatus)
    ), [data?.paymentStatus]);

    const dataRowHeader: JSX.Element[] = [
        <DataTableHeadCell key={"h_name"} sorted={false} width={"350px"}>
            <Typography variant="caption" color="secondary" fontWeight="bold">
                {"Sản phẩm"}
            </Typography>
        </DataTableHeadCell>,

        <DataTableHeadCell key={"h_quantity"} sorted={false} width={"60px"}>
            <Typography variant="caption" color="secondary" fontWeight="bold">
                {"Số lượng"}
            </Typography>
        </DataTableHeadCell>,

        <DataTableHeadCell key={"h_amount"} sorted={false}>
            <Typography variant="caption" color="secondary" fontWeight="bold">
                {"Số tiền"}
            </Typography>
        </DataTableHeadCell>,
    ];

    const dataItemRows = (item: any) => {
        let totalAmount = (item?.amount || 0);
        const currency = item?.currency || Constants.CURRENCY_DEFAULT;

        if (data?.type !== OrderType.AdditionService) {
            totalAmount = totalAmount + (item?.fee || 0);
        };

        return (
            <TableRow>
                <DataTableBodyCell key={"b_name" + item?.id} noBorder>
                    <RenderFlightInfo
                        orderType={data?.type}
                        extraInformation={item?.extraInformation}
                    />
                </DataTableBodyCell>
                <DataTableBodyCell key={"b_quantity" + item?.id} noBorder>
                    <Typography variant="caption" color="dark">
                        {`${Helpers.formatCurrency(item.quantity || 0)}`}
                    </Typography>
                </DataTableBodyCell>
                <DataTableBodyCell key={"b_amount" + item?.id} noBorder>
                    <Typography variant="caption" color={isCheck ? "error" : "dark"}>
                        {`${isCheck ? "-" : ""} ${Helpers.formatCurrency(totalAmount)} ${currency}`}
                    </Typography>
                </DataTableBodyCell>
            </TableRow>
        )
    };

    return (
        <Card>
            <Box padding={2}>
                <Typography variant="h6">
                    {"Thông tin đơn hàng"}
                </Typography>
                <Box
                    paddingTop={1}
                    paddingLeft={2}
                    paddingRight={2}
                >
                    <Box sx={{
                        gap: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "end",
                        justifyContent: "space-between",
                    }}>
                        <Box sx={{ gap: 1, display: "flex" }}>
                            <Typography variant="button">
                                {"Mã đơn hàng:"}
                            </Typography>
                            <Typography variant="button">
                                {data?.orderCode}
                            </Typography>
                        </Box>
                        <Box sx={{ gap: 1, display: "flex" }}>
                            <Typography variant="button">
                                {"Trạng thái:"}
                            </Typography>
                            <Typography variant="button">
                                {titleStatus}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box
                    paddingTop={1}
                    paddingLeft={2}
                    paddingRight={2}
                >
                    <TableContainer sx={{ boxShadow: "none" }}>
                        <Table>
                            <Box component="thead">
                                <TableRow>
                                    {dataRowHeader}
                                </TableRow>
                            </Box>
                            <TableBody>
                                {
                                    (data?.type === OrderType.AdditionService) &&
                                    <TableRow>
                                        <DataTableBodyCell key={"k"} noBorder>
                                            <Typography variant="caption" fontWeight="bold">
                                                {"Dịch vụ bổ sung"}
                                            </Typography>
                                        </DataTableBodyCell>
                                    </TableRow>
                                }
                                {
                                    (data?.type === OrderType.PartialRefund) &&
                                    <TableRow>
                                        <DataTableBodyCell key={"k"} noBorder>
                                            <Typography variant="caption" fontWeight="bold">
                                                {"Hoàn vé"}
                                            </Typography>
                                        </DataTableBodyCell>
                                    </TableRow>
                                }

                                {(data?.type === OrderType.Transfer) &&
                                    <TableRow>
                                        <DataTableBodyCell key={"k"} noBorder>
                                            <Typography variant="caption" fontWeight="bold">
                                                {"Thay đổi chuyến bay"}
                                            </Typography>
                                        </DataTableBodyCell>
                                    </TableRow>
                                }
                                {[...data?.orderDetails || []].map(item => (dataItemRows(item)))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Card>
    )
};

export default CardBasicOrder;


const RenderFlightInfo = ({ extraInformation, orderType, }: { extraInformation: any, orderType: number }) => {
    const dataExtraInfo = Helpers.converStringToJson(extraInformation);

    let textHandBaggage = "";
    let textSignedBaggage = "";
    let textCabinClass = `${"Hạng"}`;
    let textCarrier = `${"Hãng "} ${dataExtraInfo?.CarrierMarketingObj?.Code || ""}: `;

    if (!Helpers.isNullOrEmpty(dataExtraInfo?.CabinClass)) {
        textCabinClass = textCabinClass + ` ${dataExtraInfo?.CabinClass || "-/-"}`;
    }

    const valueHandBaggage = dataExtraInfo?.FreeBaggage?.find((el: any) => el.IsHandBaggage)?.Name;
    if (!Helpers.isNullOrEmpty(valueHandBaggage)) {
        textHandBaggage = `${"Hành lý xách tay"}: ${valueHandBaggage}`;
    }

    const valueSignedBaggage = dataExtraInfo?.FreeBaggage?.find((el: any) => !el.IsHandBaggage)?.Name;
    if (!Helpers.isNullOrEmpty(valueSignedBaggage)) {
        textSignedBaggage = `${"Hành lý ký gửi"}: ${valueSignedBaggage}`;
    };

    if (orderType === OrderType.AdditionService) {
        return (
            <Box gap={1} display={"flex"}>
                &ensp;-
                <Typography variant="caption">
                    {dataExtraInfo?.itemName?.["vi"]}
                </Typography>
            </Box>
        )
    }
    return (
        <Box gap={1} display={"grid"}>
            <Typography variant="caption">
                <Typography variant="caption" fontWeight="bold" color="secondary">
                    {dataExtraInfo?.DepartPlaceObj?.Name || "-/-"}
                    {/* <Typography variant="caption">
                            {` (${dataExtraInfo?.DepartPlaceObj?.Code})`}
                        </Typography> */}
                </Typography>
                &ensp;-&ensp;
                <Typography variant="caption" fontWeight="bold" color="secondary">
                    {dataExtraInfo?.ArrivalPlaceObj?.Name || "-/-"}
                    {/* <Typography variant="caption">
                            {` (${dataExtraInfo?.ArrivalPlaceObj?.Code})`}
                        </Typography> */}
                </Typography>
            </Typography>

            <Typography variant="caption">
                &emsp;-&nbsp;{textCarrier}
                <Typography variant="caption">
                    {moment(Helpers.getDateValue(dataExtraInfo?.DepartDate)).format("HH:mm")}
                    &nbsp;{Strings.Common.DAY.toLocaleLowerCase()}&nbsp;
                    {moment(Helpers.getDateValue(dataExtraInfo?.DepartDate)).format("DD/MM/YYYY")}
                </Typography>
                &ensp;-&ensp;
                <Typography variant="caption">
                    {moment(Helpers.getDateValue(dataExtraInfo?.ArrivalDate)).format("HH:mm")}
                    &nbsp;{Strings.Common.DAY.toLocaleLowerCase()}&nbsp;
                    {moment(Helpers.getDateValue(dataExtraInfo?.ArrivalDate)).format("DD/MM/YYYY")}
                </Typography>
            </Typography>

            <Typography variant="caption">
                &emsp;-&nbsp;{textCabinClass}
            </Typography>
            {textHandBaggage &&
                <Typography variant="caption">
                    &emsp;-&nbsp;{textHandBaggage}
                </Typography>
            }
            {textSignedBaggage &&
                <Typography variant="caption">
                    &emsp;-&nbsp;{textSignedBaggage}
                </Typography>
            }
        </Box>
    );
};