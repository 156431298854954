import { Box, Typography } from "@maysoft/common-component-react";
import Helpers from "commons/helpers"

const OperationHistoryOrderDataText = (props: { amount: number, currency: string }) => {
    return (
        <Box display="flex" flexDirection="row"alignItems="center" gap={1}>
            {/* <Typography variant="button" textTransform="none">Mã đơn hàng&nbsp;{"(incoming)"}</Typography>
            <span>-</span> */}
            <Typography variant="button" textTransform="none">Số tiền:&nbsp;{Helpers.formatCurrency(props.amount)} {props.currency}</Typography>
            {/* <span>-</span>
            <Typography variant="button" textTransform="none">{"incoming"}</Typography> */}
        </Box>
    )
}

export default OperationHistoryOrderDataText