// PUBLIC
export enum Status {
    Deleted = -9,
    Blocked = -2,
    Cancel = -3,
    Reject = -4,
    Draft = -1,
    Inactive = 0,
    Active = 1,
}

export enum Gender {
    Male = 1,
    Female = 2,
}

export enum IsTrue {
    False = 0,
    True = 1,
}

export enum Mode {
    Create = 0,
    Update = 1,
    View = 2,
}

export enum EditMode {
    None = 0,
    Read = 1,
    Export = 3,
    Create = 4,
    Import = 5,
    Update = 16,
    Delete = 64,
    Owner = 255,
}

export enum ActionRequest {
    Create = 0,
    Update = 1,
    Delete = -1,
    Empty = -2,
    Detail = -33,
}

export enum ActionTypeSelectBox {
    Delete = -9,
    Submit = 9,
    Cancel = 99,
    Print = 90,
}

export enum MultipleApply {
    False = 0,
    True = 1,
}

export enum ActiveStatus {
    Inactive,
    Active,
}

export enum BranchType {
    CompanyHeadOffice = 0,
    Branch = 1,
    Store = 2,
}

export enum RoleCode {
    Owner = "000000",
}

export enum UserType {
    Admin = 1,
    User = 2,
}

export enum IsMenu {
    False = 0,
    True = 1,
}

export enum ModuleType {
    Web = 0,
    App = 1,
}

export enum AccessMode {
    Public = 0,
    Private = 1,
    Internal = 2,
    PrivateRBAC = 4,
}

export enum ResourceType {
    Controller = 0,
    Module = 1,
}

export enum PermissionType {
    None = 0,
    Read = 1,
    Export = 3,
    Create = 4,
    Import = 5,
    Update = 16,
    Delete = 64,
    Owner = 255,
}

export enum GroupType {
    Company = 0,
    Department = 1,
    BranchStore = 2,
    Other = 128,
}

export enum CodenameGroup {
    Unit = "G00001", // đơn vị tính
    PackageUnit = "G00002", // đơn vị đóng gói (đơn vị chuyển đổi)
    ReturnReason = "G00003", // lý do đổi trả hàng
    ExportReason = "G00004", // lý do xuất
    TypePrice = "G00005", // loại giá
    CardIssuedPlace = "G00006", // nơi cấp CMND
}

export enum YearlyRecurring {
    FALSE = 0,
    TRUE = 1,
}

export enum ServiceName {
    Common,
    Indentity,
    SaleService,
    Asset,
}

export enum FileAccessMode {
    Public = 0,
    Authenticated = 1,
    Internal = 2,
    Private = 4,
}

export enum FileProcessingStatus {
    Unprocessed = 0,
    Processing = 1,
    Processed = 2,
}

export enum AttributeType {
    Product = 0,
    Common = 1,
    Partner = 2,
}

export enum SystemSettingCode {
    VnPay = "VNPAY_CONFIG",
    PayPal = "PAYPAL_CONFIG",
    Payment = "PAYMENT_CONFIG",
    AttributeCommon = "ATR_COMMON",
    PaymentGateway = "PAYMENT_GATEWAY",
    PartnerSetting = "PARTNER_SETTING",
}

export enum ItemType {
    Item = 0,
    Service = 1,
    ComboItem = 2,
    ComboService = 3,
    Membership = 4,
}

export enum ProductType {
    Normal = 1,
    Service = 2,
    Combo = 3,
    Membership = 10,
}

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export enum GetPagedType {
    User = 1,
    Item = 2,
    Items = 3,
    Partner = 5,
}

export enum PaymentType {
    Cash = 0, // Tiền mặt
    Banking = 1, // Chuyển khoảng
    Paypal = 2,
    VnPay = 3,
    Mobile = 4,
    Debt = 5, // Công nợ

    NinePayCollection = 10, // 9Pay QR
    NinePayATM = 11, // 9Pay Thẻ nội địa
    NinePayCredit = 12, // 9Pay thẻ visa nội địa
    NinePayCreditInternational = 13, // 9pay thẻ visa quốc tế
}

export enum OrderType {
    Sell = 0, // order tạo thẳng ở sale
    Book = 1, // order tạo từ booking
    Buy = 2, // nhập hàng (hiện tại k có sài)
    TransactionFee = 6, // này cho transactionFee bạn k cần quan tâm

    Transfer = 8, // chuyển vé
    AdditionService = 16, // mua dịch vụ
    PartialRefund = 32, // hoàn vé
}

export enum ShippingMethod {
    Direct = 0,
    Delivery = 1,
}

export enum OrderDetailType {
    Main = 0,
    Hotel = 1,
    Flight = 2,
    Other = 10,
}

export enum PaymentStatus {
    Cancel = -2,
    Pending = -1,
    New = 0,
    WaitingConfirm = 1,
    Processing = 2,
    RefundProcessing = 3,
    RefundFailed = 4,
    PartialRefundFailed = 5,
    Completed = 10,
    Failed = 11,
    Issued = 12,
    WaitingRefund = 13,
    PartialRefunded = 14,
    Refunded = 15,
}

export enum ConfirmStatus {
    Rejected = -3,
    Cancel = -2,
    Pending = -1,
    Processing = 0,
    Confirmed = 1,
    Completed = 9,
}

export enum OrderStatus {
    Cancel = -2,
    Pending = -1,
    New = 0,
    Confirm = 1,
    Editing = 2,
    PaymentConfirm = 3,
    DebtConfirm = 4,
    ExchangeRequest = 5,
    ExchangeCancel = 6,
    Completed = 10,
}

export enum ClassifyByPoint {
    ClassifyByExpense = 0,
    ClassifyByPoint = 1,
}

export enum PartnerType {
    Customer = 0,
    // IndividualCustomer = 0,
    CompanyCustomer = 1,
    IndividualSupplier = 2,
    CompanySupplier = 3,
    Shipper = 4,
    DeliveryCompany = 5,
    Staff = 6,
}

export enum DiscountType {
    Percent = 1,
    Money = 2,
}

export enum DiscountDetailType {
    BillDiscount = 1,
    Gift = 2,
    ItemDiscount = 3,
    GiftPoint = 4,
    ItemDiscountByQuantity = 5,
    GiftVoucher = 6,
    VoucherDiscount = 7,
    CustomItemDiscount = 8,
    CustomBillDiscount = 9,
    UsingPoint = 10,
    LoyaltyClassDiscount = 11,
}

export enum PromotionTargetType {
    Customer = 1,
    Branch = 2,
    Caterogy = 4,
    Item = 6,
    LoyaltyClass = 8,
}

export enum PromotionType {
    Campaign,
    Voucher,
}

export enum PromotionCriteriaItemType {
    PromotedItem = 0,
    DiscountItem = 1,
}

export enum VoucherType {
    Voucher = 1,
    Coupon = 2,
    // SpecificCutomer = 3
}

export enum PromotionApplyOn {
    Bill = 1,
    Item = 2,
    Both = 3,
}

export enum PromotionMethod {
    BillDiscount = 1,
    Gift = 2,
    ItemDiscount = 3,
    GiftPoint = 4,
    ItemDiscountByQuantity = 5,
    GiftVoucher = 6,
}

export enum CustomerType {
    IndividualCustomer = 0,
    CompanyCustomer = 1,
}

export enum StatusVariable {
    False = 0,
    True = 1,
}

export enum OrderDetailPromotion {
    None = 0,
    PromotionItem = 1,
}
export enum GroupUserDefault {
    None = 1,
    Default = 2,
}

export enum CashBookType {
    Payment = 0,
    Receive = 1,
    All = 2,
}

export enum PaymentVoucherType {
    SelfCreated = 0,
    Automatic = 1,
    //CustomerDeposit = 2,
    //CollectDebtFromShippingParners = 3
}

export enum ReceiveVoucherType {
    SelfCreated = 0,
    Automatic = 1,
    CustomersOrder = 2,
    CollectionAndTransfer = 3,
}

export enum VoucherPartnerType {
    IndividualCustomer = 0,
    CompanyCustomer = 1,
    IndividualSupplier = 2,
    CompanySupplier = 3,
    Shipper = 4,
    DeliveryCompany = 5,
    Staff = 6,
}

export enum VoucherHistoryStatus {
    Cancel = -2, // Hủy
    Commit = 0, // xác nhận thanh toán
    CreateAuto = 1, // Tạo tự động
    Pending = 2, // Đang xử lý
    Updated = 3, // Đã chỉnh sửa
    Completed = 9, // Hoàn thành
}

export enum RevenueRecognition {
    False = 0,
    True = 1,
}

export enum IsCustomerOrSupplier {
    Customer = 0,
    Supplier = 1,
    Shipper = 4,
    Staff = 6,
}

export enum UserGroup {
    Customer = 0, // Khách hàng
    Supplier = 1, // Nhà cung cấp
    Other = 2, // Đối tác
}

export enum ContactDefault {
    None = 0,
    Default = 1,
}

export enum AttributeValueType {
    Number = 0,
    String = 1,
    MultiLanguage = 2,
    Upload = 3,
}

export enum ServiceOrganizationAction {
    Create = 0,
    Update = 1,
    Delete = -1,
}

export enum MessageRequestType {
    Absence = 0,
    ReportWork = 1,
    Invitation = 2,
    ForgotPassWord = 3,
    ResetPass = 4,
    Other = 9,
}

export enum MessageType {
    PushNotification,
    SMS,
    Email,
}

export enum ReadStatus {
    UnRead,
    Read,
}

export type MsgType = "warning" | "success" | "error" | "info";

export enum AvailableLanguage {
    Vietnamese = "vi",
    English = "en",
}

export enum TemplateType { // Template
    None = 0,
    BluePrint = 1,
    Template = 2,
}

export enum TemplateAddAttribute { // AddAttribute
    None = 0,
    Allow = 1,
}

export enum PartnerUserFilter {
    False = 0,
    True = 1,
}

export enum AttributeValueSourceType {
    Static = 1,
    ApiData = 2,
    Input = 4,
}

// Booking

export enum ItineraryType {
    Flight = 1,
    Hotel = 2,
    Car = 4,
    Train = 8,
    InTrip = 16,
    Other = 256,
}

export enum SupplierCode {
    Nolmal = "000000",
    Expedia = "000001",
    Deeptech = "000002",
}
//

export enum OrganizationType {
    Normal = 0,
    Main = 1,
}

export enum ServiceFeeTargetType {
    Partner = 1,
    Membership = 2,
    LoyaltyClass = 4,
    Team = 8,
    All = 256,
}

export enum CriteriaType {
    Percentage = 1,
    FixedAmount = 2,
    //Item = 4,
    Free = 4,
}

export enum NeedApprove {
    None = 0,
    Approve = 1,
}

// booking detail popup type
export enum BookingDetailAmendType {
    Normal = 0,
    Transfer = 8,
    AdditionService = 16,
    PartialRefund = 32,
}
export enum OrderPricingType {
    Normal = 0, // Hệ thống tự tính
    External = 1, // Người dùng nhập
}

export enum CabinClass {
    EconomyClass = "ECONOMY_CLASS",
    PremiumEconomyClass = "PREMIUM_ECONOMY_CLASS",
    BusinessClass = "BUSINESS_CLASS",
    FirstClass = "FIRST_CLASS",
}

export enum CashBookTypeV2 {
    Refund = 1, // Hoàn tiền
    Deposit = 2, // Nạp tiền
    Pay = 3, // Thanh toán
}

export enum SlipType {
    SelfCreated = 0,
    Automatic = 1,
}

export enum SlipStatus {
    Rejected = -3,
    Cancel = -2,
    Commit = 0,
    CreateAuto = 1,
    Pending = 2,
    Updated = 3,
    Failed = 4,
    Completed = 9,
}
