import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Card, Grid, IconButton, Tooltip } from "@mui/material";
import { Add, FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import {
    DataTable, DataTableStatusCell,
    DatePicker, Autocomplete, Box, Button, Chip,
    FormField, Typography, useCommonComponentContext,
    CustomPagination
} from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import useDataRequestBooking from "./useDataRequestBooking.hook";
import { ItineraryType, Mode, NeedApprove, PaymentStatus, SupplierCode } from "constants/enum";
import RequestBookingService, { IRecordBookingV2, IReqGetPagedBookingV2 } from "services/booking/requestBooking.service";



const requestBookingService = new RequestBookingService();

export type IDataRequestGetPaged = IReqGetPagedBookingV2 & { totalCount?: number; }

const RequestBookingContainer = (props: {
    title?: string;

    type?: ItineraryType;
    supplierCode?: SupplierCode;
    hidenActionCreate?: boolean;
    hidenActionUpdate?: boolean;
    hidenActionExport?: boolean;

    requestGetPaged: IDataRequestGetPaged;

    onNavigate: (data?: {
        id?: string;
        mode?: number;
    }) => void;
    onGetPaged: (data: {
        query: string;
        totalCount: number;
    }) => void;
}) => {

    const {
        userInfo,
        onError,
        onSuccess,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const {
        requestStatusList,
        paymentStatusList,
        itineraryTypeList,
        getDataByPaymentStatus,
        getDataByRequestStatus,
        getDataMapOrderPriceByIds,
        getDataMapOrganizationByListID
    } = useDataRequestBooking();

    const [model, setModel] = useState<{
        requestData?: IDataRequestGetPaged;
        requestDataTemp?: IDataRequestGetPaged;
    }>({});

    const [dataOrg, setDataOrg] = useState<Map<string, any>>(new Map());
    const [dataOrderPrice, setDataOrderPrice] = useState<Map<string, any>>(new Map());

    const [dataGetPaged, setDataGetPaged] = useState<IRecordBookingV2[]>([]);

    const [loadingDataTable, setLoadingDataTable] = useState<boolean>(false);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile?.organizationId)) {

            const newReq: IDataRequestGetPaged = {
                code: props?.requestGetPaged?.code,
                buyer: props?.requestGetPaged?.buyer,
                seller: props?.requestGetPaged?.seller,
                searchText: props?.requestGetPaged?.searchText,
                paymentStatus: props?.requestGetPaged?.paymentStatus,
                requestStatus: props?.requestGetPaged?.requestStatus,
                confirmStatus: props?.requestGetPaged?.confirmStatus,
                bookingEndTime: props?.requestGetPaged?.bookingEndTime,
                bookingStartTime: props?.requestGetPaged?.bookingStartTime,

                type: props?.requestGetPaged?.type,
                pageSize: props?.requestGetPaged?.pageSize,
                pageNumber: props?.requestGetPaged?.pageNumber,
                totalCount: props?.requestGetPaged?.totalCount,
            };

            getPagedBooking(newReq);
        }
    }, [props?.requestGetPaged, userInfo?.userProfile?.organizationId]);

    const getPagedBooking = async (req?: IDataRequestGetPaged) => {
        try {
            // onShowLoading();
            setLoadingDataTable(true);

            const pageSize = req?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(req?.pageNumber || 1, pageSize, req?.totalCount || 0) || 1;

            let requestData: any = {
                code: req?.code,
                buyer: req?.buyer,
                seller: req?.seller,
                searchText: req?.searchText,
                supplierCode: props.supplierCode,
                requestStatus: req?.requestStatus,
                confirmStatus: req?.confirmStatus,
                paymentStatus: req?.paymentStatus,
                bookingEndTime: req?.bookingEndTime ?? moment().endOf("month").unix(),
                bookingStartTime: req?.bookingStartTime ?? moment().startOf("month").unix(),
                type: Helpers.isNullOrEmpty(props.type) ? req?.type : props.type,

                pageSize,
                pageNumber,
            };

            const result = await requestBookingService.getPagedV2({
                ...requestData,
                organizationId: userInfo?.userProfile?.organizationId,
            });

            const orderIds = [...result.items || []].map(el => el.orderId);
            const newDataOrderPrice = await getDataMapOrderPriceByIds(orderIds);

            const idOrgs = [...result.items || []].map(el => el.organizationId);
            const newDataOrg = await getDataMapOrganizationByListID(idOrgs);


            setDataOrg(newDataOrg);
            setDataOrderPrice(newDataOrderPrice);

            setDataGetPaged(result.items);

            setModel({
                requestData: { ...requestData, totalCount: result.totalCount },
                requestDataTemp: { ...requestData, totalCount: result.totalCount },
            });

            const query = `?${Helpers.handleFormatParams(requestData)}`;

            props?.onGetPaged({ query, totalCount: result.totalCount });

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            // onHideLoading();
            setLoadingDataTable(false);
        }
    };

    const exportCSV = async () => {
        Helpers.showConfirmAlert("Bạn chắc chắn muốn tải file về", async () => {
            try {
                onShowLoading();

                const result = await requestBookingService.exportCsv({
                    organizationId: userInfo?.userProfile?.organizationId,
                    bookingEndTime: model?.requestData?.bookingEndTime,
                    bookingStartTime: model?.requestData?.bookingStartTime,

                    code: model?.requestData?.code,
                    supplierCode: props.supplierCode,
                    searchText: model?.requestData?.searchText,
                    paymentStatus: model?.requestData?.paymentStatus,
                    requestStatus: model?.requestData?.requestStatus,
                    type: Helpers.isNullOrEmpty(props.type) ? (model?.requestData?.type) : props.type,
                });

                const date = moment().format("HH_mm_DD_MM_YYYY");

                var fileName = `Flight_Booking_${date}.csv`;
                const downloadUrl = URL.createObjectURL(result);
                const a = document.createElement("a");

                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

            } catch (error) {
                const e = Helpers.renderExceptionError(error);
                onError(e);
            } finally {
                onHideLoading();
            }
        });
    };

    // #region Filter Form
    const RenderFilterForm = () => (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormField
                        label={"Mã đơn hàng/ đặt chỗ"}
                        placeholder={"Nhập mã đơn hàng/ đặt chỗ"}
                        defaultValue={model.requestDataTemp?.code}
                        onBlur={(value: any) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    code: value,
                                }
                            }));
                        }}
                    />
                </Grid>

                {Helpers.isNullOrEmpty(props.type) &&
                    <Grid item xs={12}>
                        <Autocomplete
                            label={"Loại đặt chỗ"}
                            placeholder={"Chọn loại đặt chỗ"}
                            data={itineraryTypeList || []}
                            defaultValue={model.requestDataTemp?.type}
                            onChange={(value) => {
                                const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                                setModel(prev => ({
                                    ...prev,
                                    requestDataTemp: {
                                        ...prev.requestDataTemp,
                                        type: newValue,
                                    }
                                }));
                            }}
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <DatePicker
                        label={"Thời gian"}
                        views={["month", "year"]}
                        placeholder={"Chọn thời gian"}
                        value={Helpers.getDateValue(model?.requestDataTemp?.bookingStartTime)}
                        onChangeValue={(value: any) => {
                            const valStartTime = !Helpers.isNullOrEmpty(value) ? moment(value).startOf("month").unix() : undefined;

                            const valEndTime = !Helpers.isNullOrEmpty(value) ? moment(value).endOf("month").unix() : undefined;

                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    bookingEndTime: valEndTime,
                                    bookingStartTime: valStartTime,
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        label={"Khách hàng/ Công ty"}
                        placeholder={"Nhập khách hàng/ Công ty"}
                        defaultValue={model.requestDataTemp?.searchText}
                        onBlur={(value: any) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    searchText: value,
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Trạng thái đặt chỗ"}
                        placeholder={"Chọn trạng thái đặt chỗ"}
                        data={paymentStatusList || []}
                        defaultValue={model.requestDataTemp?.paymentStatus}
                        onChange={(value) => {
                            const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    paymentStatus: newValue,
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Trạng thái duyệt"}
                        placeholder={"Chọn trạng thái duyệt"}
                        data={requestStatusList || []}
                        defaultValue={model.requestDataTemp?.requestStatus}
                        onChange={(value) => {
                            const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    requestStatus: newValue,
                                }
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );

    // #region Chip Value Filter
    const RenderChipValueFilter = () => (
        <>
            {(
                !Helpers.isNullOrEmpty(model?.requestData?.code) ||
                !Helpers.isNullOrEmpty(model?.requestData?.searchText) ||
                !Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) ||
                !Helpers.isNullOrEmpty(model?.requestData?.requestStatus) ||
                !Helpers.isNullOrEmpty(model?.requestData?.bookingEndTime) ||
                !Helpers.isNullOrEmpty(model?.requestData?.bookingStartTime)
            ) &&
                <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                    {!Helpers.isNullOrEmpty(model?.requestData?.bookingStartTime) &&
                        !Helpers.isNullOrEmpty(model?.requestData?.bookingEndTime) &&
                        <Chip
                            label={"Thời gian"}
                            value={[
                                moment(Helpers.getDateValue(model?.requestData?.bookingStartTime)).format("DD/MM/YYYY"),
                                moment(Helpers.getDateValue(model?.requestData?.bookingEndTime)).format("DD/MM/YYYY")
                            ].join(" - ")}
                            onDelete={() => {
                                getPagedBooking({
                                    ...model.requestData,
                                    bookingEndTime: moment().endOf("month").unix(),
                                    bookingStartTime: moment().startOf("month").unix(),
                                });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.code) &&
                        <Chip
                            label={"Mã đơn hàng/ đặt chỗ"}
                            value={model?.requestData?.code}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, code: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.searchText) &&
                        <Chip
                            label={"Khách hàng/ Công ty"}
                            value={model?.requestData?.searchText}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, searchText: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.requestStatus) &&
                        <Chip
                            label={"Trạng thái duyệt"}
                            value={[...requestStatusList || []].find(el => el.code === model?.requestData?.requestStatus)?.name || ""}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, requestStatus: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) &&
                        <Chip
                            label={"Trạng thái đặt chỗ"}
                            value={[...paymentStatusList || []].find(el => el.code === model?.requestData?.paymentStatus)?.name || ""}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, paymentStatus: undefined });
                            }}
                        />
                    }
                </Box>
            }
        </>
    );

    // #region Data Columns
    const dataColumns = useMemo(() => ([
        {
            Header: Strings.Common.ACTION, accessor: "action", width: "60px",
            Cell: (row: any) => (
                <IconButton color="info" onClick={() => {
                    props?.onNavigate({ id: row?.row?.original?.id, mode: Mode.View });
                }}>
                    <InfoOutlined />
                </IconButton>
                // <Link className="hyperlink" to={`${Screens.PAYMENT_DETAIL}?id=${row.row?.original?.id}`}>
                //     {Strings.Common.DETAIL}
                // </Link>
            ),
        },
        {
            Header: "Thời gian đặt", accessor: "createTime", width: "120px",
            Cell: (row: any) => {
                const newValue = Helpers.getDateValue(row?.value);
                return (
                    <Box display="grid">
                        <Typography variant="caption" fontWeight="bold" color="secondary">
                            {newValue ? moment(newValue).format("HH:mm DD/MM/YYYY") : "-"}
                        </Typography>
                    </Box>
                )
            },
        },
        {
            Header: "Booking Code", accessor: "externalBookingCode", width: "90px",
        },
        {
            Header: "Mã đơn hàng", accessor: "bookingCode", width: "80px",
            Cell: (row: any) => {
                const orderCode = dataOrderPrice.get(row?.row?.original?.orderId)?.orderCode || "-";
                return <>{orderCode}</>
            }
        },
        {
            Header: "Mã đặt chỗ", accessor: "reservationCode", width: "80px",
            Cell: (row: any) => {
                if (Helpers.isNullOrEmpty(row?.row?.original?.extraInfo)) {
                    return (<></>)
                } else {
                    const extraInfo = Helpers.converStringToJson(row?.row?.original?.extraInfo);

                    if (extraInfo && !Helpers.isNullOrEmpty(extraInfo?.IssueTicketInfo)) {
                        const issueTicketInfo = extraInfo && Helpers.converStringToJson(extraInfo?.IssueTicketInfo);
                        return (<> {issueTicketInfo?.Booking?.Itineraries?.[0]?.reservation_code || "-"}  </>)
                    } else {
                        return (<>-</>)
                    }
                }
            }
        },
        {
            Header: "Khách hàng", accessor: "name", width: "200px",
            Cell: (row: any) => (
                <Box display="grid">
                    <Typography variant="caption" fontWeight="bold" color="secondary">
                        {row?.value}
                    </Typography>
                </Box>
            ),
        },
        {
            Header: "Số điện thoại", accessor: "phoneNumber", width: "100px",
            Cell: (row: any) => {
                const value = row.value || "-";
                const extraInfoParsed = Helpers.converStringToJson(row.row.original.extraInfo);

                if (extraInfoParsed.Contact) {
                    const { phone_number, phone_code } = extraInfoParsed.Contact;
                    return <>+{phone_code}{phone_number}</>
                }

                return <>{value}</>
            }

        },
        {
            Header: "Công ty", accessor: "organizationId", width: "200px",
            Cell: (row: any) => <>{dataOrg.get(row.value)?.name || "-"}</>
        },
        {
            Header: "Tổng tiền", accessor: "totalAmount", width: "120px",
            Cell: (row: any) => {
                const currency = row?.row?.original?.currency || Constants.CURRENCY_DEFAULT;
                let totalAmount = (row?.value || 0) + (row?.row?.original?.serviceFee || 0) + (0);

                if (!Helpers.isNullOrEmpty(row?.row?.original?.orderId)) {
                    totalAmount = dataOrderPrice.get(row?.row?.original?.orderId)?.amount || 0;
                }

                return (
                    <>{`${Helpers.formatCurrency(totalAmount)} ${currency}`}</>
                );
            },
        },
        {
            Header: "Trạng thái duyệt", accessor: "itineraryDetailConfirmStatus",
            Cell: (row: any) => (
                (Number(row?.row?.original?.needApprove) === NeedApprove.Approve)
                    ? <DataTableStatusCell data={getDataByRequestStatus(Number(row?.value))} />
                    : <></>
            ),
        },
        {
            Header: "Trạng thái đặt chỗ", accessor: "paymentStatus",
            Cell: (row: any) => {

                let reservation_code = "";
                const extraInfo = Helpers.converStringToJson(row?.row?.original?.extraInfo);
                const valueConver = extraInfo?.LastTicketDate ? Helpers.convertDDMM_To_MMDD(extraInfo?.LastTicketDate) : undefined;
                const valueUnix = valueConver ? moment.utc(valueConver).unix() : undefined;

                if (extraInfo && !Helpers.isNullOrEmpty(extraInfo?.IssueTicketInfo)) {
                    const issueTicketInfo = Helpers.converStringToJson(extraInfo?.IssueTicketInfo);
                    reservation_code = issueTicketInfo?.Booking?.Itineraries?.[0]?.reservation_code;
                };

                const newdata = getDataByPaymentStatus({
                    lastTicketDate: valueUnix,
                    paymentStatus: Number(row?.value),
                    reservationCode: reservation_code,
                    confirmStatus: Number(row?.row?.original?.confirmStatus),
                    itineraryDetailConfirmStatus: Number(row?.row?.original?.itineraryDetailConfirmStatus),
                });

                return (
                    <DataTableStatusCell data={newdata} />
                )
            },
        },
    ]), [dataOrg, getDataByRequestStatus]);

    // #region Row Action
    const actionList = (row: IRecordBookingV2) => {
        return [
            {
                key: "view",
                title: "Xem",
                iconName: "view",
                callback: (row: any) => {
                    props?.onNavigate({ id: row?.id, mode: Mode.View });
                }
            },
        ] as any;
    };

    return (
        <Box>
            <Grid container spacing={3}>
                {/* Button Action */}
                {(
                    !props.hidenActionCreate ||
                    !props.hidenActionExport ||
                    !Helpers.isNullOrEmpty(props.title)
                ) &&
                    <Grid item xs={12}>
                        <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: !Helpers.isNullOrEmpty(props.title) ? "space-between" : "end",
                        }}>
                            {
                                !Helpers.isNullOrEmpty(props.title) &&
                                <Typography variant="h5">{props.title}</Typography>
                            }
                        </Box>
                    </Grid>
                }

                <Grid item xs={12}>
                    <RenderChipValueFilter />

                    <Card>
                        <Box sx={{
                            padding: 2,
                            width: '100%',
                            overflow: "auto",
                        }}>
                            <DataTable
                                showFooter={false}
                                loading={loadingDataTable}
                                rowPerPageOptions={[20, 50, 100]}
                                rowPerPage={model.requestData?.pageSize}
                                totalCount={model.requestData?.totalCount || 0}
                                pageNumber={model.requestData?.pageNumber || 1}
                                onChangePageSize={(pageSize) => { getPagedBooking({ ...model.requestData, pageSize }) }}
                                onChangePageNumber={(pageNumber) => { getPagedBooking({ ...model.requestData, pageNumber }) }}

                                table={{
                                    rows: dataGetPaged,
                                    columns: dataColumns,
                                }}

                                // actionList={actionList}

                                filterForm={<RenderFilterForm />}

                                onFilter={() => {
                                    getPagedBooking({ ...model.requestDataTemp, pageNumber: 1, })
                                }}
                                onReset={() => {
                                    getPagedBooking({
                                        pageNumber: 1,
                                        pageSize: Constants.ROW_PER_PAGE,
                                    });
                                }}
                                onCloseFilter={() => {
                                    const newData = { ...model.requestData };
                                    setModel({
                                        ...model,
                                        requestDataTemp: newData
                                    });
                                }}

                                leftElement={
                                    <>
                                        {
                                            !props.hidenActionExport &&
                                            <Button
                                                color="success"
                                                disabled={loadingDataTable}
                                                onClick={() => { exportCSV(); }}
                                            >
                                                <FileDownloadOutlined /> &nbsp; {Strings.Common.DOWNLOAD}
                                            </Button>
                                        }
                                    </>
                                }
                            />
                            <CustomPagination
                                isList
                                rowsPerPageOptions={[20, 50, 100]}
                                page={model?.requestData?.pageNumber}
                                pageSize={model?.requestData?.pageSize}
                                total={model?.requestData?.totalCount}
                                onChangePage={(value) => {
                                    getPagedBooking({ ...model.requestData, pageNumber: value })
                                }}
                                onChangePageSize={(value) => {
                                    getPagedBooking({ ...model.requestData, pageSize: value })
                                }}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RequestBookingContainer;

// 
// !props.hidenActionUpdate &&
// ![ConfirmStatus.Confirmed, ConfirmStatus.Cancel].includes(row?.confirmStatus) &&
// {
//     key: "edit",
//     iconName: "edit",
//     title: "Chỉnh sửa",
//     callback: (row: any) => {
//         props?.onNavigate({ id: row?.id, mode: Mode.Update });
//     }
// },

// ![ConfirmStatus.Confirmed, ConfirmStatus.Cancel].includes(row?.confirmStatus) &&
// {
//     key: "confirm",
//     iconName: "check",
//     title: "Xác nhận yêu cầu đặt chỗ",
//     callback: (row: any) => onConfirm(row?.id),
// },

// [ConfirmStatus.Pending, ConfirmStatus.Processing].includes(row?.confirmStatus) &&
// {
//     key: "delete",
//     iconName: "delete",
//     title: "Xóa yêu cầu đặt chỗ",
//     callback: (row: any) => onDelete(row?.id)
// },

// ![ConfirmStatus.Pending, ConfirmStatus.Processing, ConfirmStatus.Confirmed].includes(row?.confirmStatus) &&
// {
//     key: "cancel",
//     iconName: "close",
//     title: "Hủy yêu cầu đặt chỗ",
//     callback: (row: any) => onCancel(row?.id)
// }

// const onCancel = async (id: string) => {
//     Helpers.showConfirmAlert("Bạn chắc chắn muốn hủy yêu cầu đặt chỗ này", async () => {
//         try {
//             onShowLoading();

//             await requestBookingService.cancel(id);

//             await getPagedBooking(model.requestData);

//             onSuccess("Hủy yêu cầu đặt chỗ thành công");

//         } catch (error) {
//             const e = Helpers.renderExceptionError(error);
//             onError(e);
//         } finally {
//             onHideLoading();
//         }
//     });
// }

// const onDelete = async (id: string) => {
//     Helpers.showConfirmAlert("Bạn chắc chắn muốn xóa yêu cầu đặt chỗ này", async () => {
//         try {
//             onShowLoading();

//             await requestBookingService.delete(id);

//             await getPagedBooking(model.requestData);

//             onSuccess("Xóa yêu cầu đặt chỗ thành công");
//         } catch (error) {
//             const e = Helpers.renderExceptionError(error);
//             onError(e);
//         } finally {
//             onHideLoading();
//         }
//     });
// }

// const onConfirm = async (id: string) => {
//     Helpers.showConfirmAlert("Bạn chắc chắn muốn xác nhận yêu cầu đặt chỗ này", async () => {
//         try {
//             onShowLoading();

//             await requestBookingService.confirm({ bookingId: id });

//             await getPagedBooking(model.requestData);

//             onSuccess("Xác nhận yêu cầu đặt chỗ thành công");

//         } catch (error) {
//             const e = Helpers.renderExceptionError(error);
//             onError(e);
//         } finally {
//             onHideLoading();
//         }
//     });
// }