import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IRequestGetPagedTicketFund, TicketFundModule } from "@maysoft/sale-common-react";

import Screens from "constants/screens";
import Constants from "../../constants";
import useModule from "hooks/useModule";
import TicketFundService from "services/sale/ticketFund.service";

import { DashboardLayout } from "layout";

const ticketFundService = new TicketFundService();
const RequestDepositScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        startTime: searchParams.get("startTime"),
        endTime: searchParams.get("endTime"),
        confirmStatus: searchParams.get("confirmStatus"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.REQUEST_DEPOSIT_LIST,
            detail: Screens.REQUEST_DEPOSIT_EDIT,
        },
        keyOfStrings: "REQUEST_DEPOSIT",
    });

    const handleExport = async (request: IRequestGetPagedTicketFund) => {
        const result = await ticketFundService.export(request);

        const date = moment().format("HH_mm_DD_MM_YYYY");

        var fileName = `TIcket_Fund_${date}.csv`;
        const downloadUrl = URL.createObjectURL(result);
        const a = document.createElement("a");

        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    };

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <TicketFundModule {...moduleProps} handleExport={handleExport} />
        </DashboardLayout>
    );
};

export default RequestDepositScreen;
