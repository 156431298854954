import Helpers from "commons/helpers";
import Constants from "constants/index";
import PartnerService from "services/sale/partner.service";
import OrganizationService from "services/identity/organization.service";

import { ICodename, IMultiLang } from "commons/interfaces";
import { IPersionalInfo } from "components/Booking/useDataRequestBooking.hook";
import { CashBookType, CashBookTypeV2, PaymentType, SlipStatus, SlipType } from "constants/enum";



export interface IRecordCashBookV2 {
    id: string
    referenceCode: string
    organizationId: string
    amount: number
    currency: string
    paymentMethod: number
    slipStatus: number
    slipCode: string
    type: number
    date: string
    description: string
    cashBookType: number
}

export interface IDetailCashBookV2 {
    id: string
    referenceCode: string
    organizationId: string
    amount: number
    currency: string
    paymentMethod: number
    slipStatus: number
    slipCode: string
    type: number
    date: string
    description: string
    cashBookType: number
    invoices: IInvoiceCashBookV2[]
    depositInfo: {
        note: string
        createUser: string
        approvalUser: string
    }
}

export interface IInvoiceCashBookV2 {
    id: string
    orderId: string
    seller: string
    buyer: string
    invoiceCode: string
    invoiceDate: string
    discount: number
    amount: number
    tax: number
    note: string
    currency: string
    paymentStatus: number
    extraInformation: any
    details: IDetailInvoiceCashBookV2[]
}

export interface IDetailInvoiceCashBookV2 {
    id: string
    invoiceId: string
    type: number
    itemId: string
    description: any
    quantity: number
    discount: number
    amount: number
    tax: number
    unitPrice: number
    extraInformation: string
    productName: IMultiLang
    itemName: any
    itemDescription: any
    itemPhotos: any
    itemAttributes: any
    comboItems: any
    taxDetails: any
}


const useDataPaymentInvoice = () => {

    const paymentTypeList: ICodename[] = [
        {
            code: PaymentType.Debt,
            name: "Quỹ xuất vé",
        },
        {
            code: PaymentType.NinePayATM,
            name: "Thẻ nội địa",
        },
        {
            code: PaymentType.NinePayCollection,
            name: "QR code",
        },
        {
            code: PaymentType.NinePayCredit,
            name: "Thẻ quốc tệ tại VN",
        },
        {
            code: PaymentType.NinePayCreditInternational,
            name: "Thẻ quốc tế ngoài VN",
        },
    ];

    const cashBookTypeList: ICodename[] = [
        {
            code: CashBookTypeV2.Deposit,
            name: "Nạp tiền",
        },
        {
            code: CashBookTypeV2.Pay,
            name: "Thanh toán",
        },
        {
            code: CashBookTypeV2.Refund,
            name: "Hoàn tiền",
        }
    ];

    const getSlipStatusCell = (slipStatus: SlipStatus) => {
        switch (slipStatus) {
            case SlipStatus.Updated:
                return { icon: "", color: "secondary", title: "Đã cập nhật" };
            case SlipStatus.CreateAuto:
                return { icon: "", color: "secondary", title: "Tạo tự động" };
            case SlipStatus.Rejected:
                return { icon: "close", color: "secondary", title: "Đã từ chối" };
            case SlipStatus.Cancel:
                return { icon: "cancel", color: "secondary", title: "Đã hủy" };
            case SlipStatus.Commit:
                return { icon: "rotate_left_outlined", color: "info", title: "Đã xác nhận" };
            case SlipStatus.Pending:
                return { icon: "rotate_left_outlined", color: "warning", title: "Chờ xử lý" };
            case SlipStatus.Failed:
                return { icon: "warning", color: "error", title: "Thất bại" };
            case SlipStatus.Completed:
                return { icon: "euro", color: "success", title: "Thành công" };
            default:
                return { icon: "", color: "secondary", title: "Chưa xác định" };
        }

    };

    const getSlipTypeCell = ({ slipType, type }: { slipType: CashBookType, type: SlipType }) => {
        if (slipType === CashBookType.Payment) {
            return { icon: "", color: "error", title: "Hoàn tiền", code: CashBookTypeV2.Refund, };
        } else if ((slipType === CashBookType.Receive) && (type === SlipType.SelfCreated)) {
            return { icon: "", color: "success", title: "Nạp tiền", code: CashBookTypeV2.Deposit, };
        } else {
            return { icon: "", color: "secondary", title: "Thanh toán", code: CashBookTypeV2.Pay, }
        }
    }

    const getAmountCell = ({ slipType, type, amount, currency }: {
        type: SlipType,
        amount: number,
        currency?: string,
        slipType: CashBookType,
    }) => {
        const formatCurrency = `${Helpers.formatCurrency(Number(amount))} ${currency || Constants.CURRENCY_DEFAULT}`;
        if (slipType === CashBookType.Payment) {
            return { icon: "", color: "error", title: `-${formatCurrency}` }
        } else if (slipType === CashBookType.Receive && type === SlipType.SelfCreated) {
            return { icon: "", color: "success", title: `${formatCurrency}` }
        } else {
            return { icon: "", color: "secondary", title: `${formatCurrency}` }
        }
    };

    const getPartnerBySelectId = async (ids: string[], organizationId: string) => {
        try {
            if (!Helpers.isNullOrEmpty(organizationId)) {
                const dataMap: Map<string, IPersionalInfo> = new Map();

                const result = await new PartnerService().getPaged(Helpers.handleFormatParams({
                    pageNumber: 1,
                    selectedIds: ids,
                    pageSize: ids.length,
                    organizationId: organizationId,
                }));

                [...result?.selectedItems || []].forEach(el => {
                    if (ids.includes(el.id)) {
                        const partnerUsers = [...el.partnerUsers || []]?.find(u => u.primaryUser === 1);
                        dataMap.set(el.id, {
                            id: el.id,
                            name: el.partnerName,
                            email: partnerUsers?.email || "",
                            phoneNumber: partnerUsers?.phoneNumber || "",
                        });
                    }
                });

                return dataMap;
            } else {
                return new Map();
            }
        } catch (error) {
            return new Map();
        }
    };

    const getOrganizationBySelectId = async (ids: string[]) => {
        try {

            const result = await new OrganizationService().getOrganizationByIds(ids);

            const newData: Map<string, any> = new Map();

            [...result || []].forEach(el => {
                newData.set(el.id, el);
            });

            return newData;

        } catch (error) { return new Map(); }
    };

    return {
        paymentTypeList,
        cashBookTypeList,
        getAmountCell,
        getSlipTypeCell,
        getSlipStatusCell,
        getPartnerBySelectId,
        getOrganizationBySelectId,
    };
};

export default useDataPaymentInvoice;
