
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

import { Card } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import { ICodename } from "commons/interfaces";
import { IOrderHistorie } from "services/sale/order.service";



const CardHistoriesOrder = ({
    listOrderStatus,
    dataOrderHistories,
}: {
    listOrderStatus: ICodename[];
    dataOrderHistories?: IOrderHistorie[];
}) => {

    return (
        <Card>
            <Box p={2}>
                <Typography variant="h6">
                    {"Lịch sử đơn hàng"}
                </Typography>
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {
                        dataOrderHistories.map((item, index) => (
                            <TimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineDot color="info" />
                                    {
                                        ((index + 1) < dataOrderHistories.length)
                                        && <TimelineConnector sx={{ backgroundColor: "#1A73E8" }} />
                                    }
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box display="flex" flexDirection="column" width={"100%"}>
                                        <Typography variant="button" color="info">
                                            {listOrderStatus?.find(el => el.code === item.orderStatus)?.name || "-"}
                                        </Typography>
                                        {/* <Typography variant="caption" fontStyle="italic">
                                            {"("}
                                            {
                                                Helpers.formatDate(
                                                    Helpers.getDateValue(item.updateTime),
                                                    Constants.DateFormat.HHMM_DDMMYYYY
                                                )
                                            }
                                            {")"}
                                        </Typography> */}
                                        <Typography variant="button">
                                            NV:&nbsp;{item.createUserName}
                                        </Typography>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        ))
                    }
                </Timeline>
            </Box>
        </Card>
    )
};

export default CardHistoriesOrder;


